.wrapperContent {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-top: 56px;
  padding-bottom: 56px;
  border-bottom: 1px solid #292e33;

  
  h2 {
    display: none;
  }


  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    // display: none;
    display: flex;
    flex-direction: column;
    // margin-bottom: 56px;

    
  }
}
