.table {
  margin-bottom: 100px;
  overflow: scroll;
  
  &__title {
    font-size: 14px;
    line-height: 24px;
    color: #676d73;
    margin-bottom: 25px;

    &::after {
      content: url(./../../../media/images/icons/table-arr-l.svg);
      background-size: contain;
      margin-left: 10px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  &__col-1 {
    width: 160px;

    &--title {
      height: 70px;
      width: 50%;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 70px;
    }
  }

  &__col-2 {
    width: 160px;
    &--title {
      height: 70px;
      // height: 48px;
      font-size: 16px;
      font-weight: 700;
      text-align: right;
      color: #292e33;
      margin-bottom: 70px;
    }
  }

  &__col-3 {
    padding-top: 70px;
    width: 160px;

    &--pag {
      height: 70px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 10px;

      &--numb {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #005baa;
      }

      &--pag {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &--pagImg-1 {
      background-image: url(./../../../media/images/icons/pag-1.svg);
      width: 10px;
      height: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pagImg-2 {
      background-image: url(./../../../media/images/icons/pag-2.svg);
      width: 10px;
      height: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pagPag {
      width: 8px;
      height: 8px;
      background-color: #62c960;
      border-radius: 50%;
    }

    &--wrapper {
      background-image: url(./../../../media/images/bg_tb-2-3.svg);
      background-size: 146px 480px;
      background-position: 20px 44px;
      background-repeat: no-repeat;
    }
  }

  &__col-4 {
    padding-top: 70px;
    width: 160px;

    &--pag {
      height: 70px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 10px;

      &--numb {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #005baa;
        opacity: 70%;
      }

      &--pag {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &--pagImg-1 {
      background-image: url(./../../../media/images/icons/pag-1.svg);
      width: 10px;
      height: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pagImg-2 {
      background-image: url(./../../../media/images/icons/pag-2.svg);
      width: 10px;
      height: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pagPag {
      width: 8px;
      height: 8px;
      background-color: #62c960;
      border-radius: 50%;
    }

    &--wrapper {
      background-image: url(./../../../media/images/bg_tb-2-4.svg);
      background-size: 128px 480px;
      background-position: 32px 44px;
      background-repeat: no-repeat;
    }
  }

  &__col-5 {
    padding-top: 70px;
    width: 160px;

    &--pag {
      height: 70px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 10px;

      &--numb {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #005baa;
        opacity: 40%;
      }

      &--pag {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &--pagImg-1 {
      background-image: url(./../../../media/images/icons/pag-1.svg);
      width: 10px;
      height: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pagImg-2 {
      background-image: url(./../../../media/images/icons/pag-2.svg);
      width: 10px;
      height: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pagPag {
      width: 8px;
      height: 8px;
      background-color: #62c960;
      border-radius: 50%;
    }

    &--wrapper {
      background-image: url(./../../../media/images/bg_tb-2-5.svg);
      background-size: 120px 480px;
      background-position: 40px 44px;
      background-repeat: no-repeat;
    }
  }

  &__col-6 {
    position: relative;
    width: 160px;
    padding-top: 70px;

    &--title {
      position: absolute;
      top: 0;
      left: -295px;
      width: 453px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: #292e33;
    }

    &--pag {
      height: 70px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 10px;

      &--numb {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #005baa;
        opacity: 20%;
      }

      &--pag {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &--pagImg-1 {
      background-image: url(./../../../media/images/icons/pag-1.svg);
      width: 10px;
      height: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pagImg-2 {
      background-image: url(./../../../media/images/icons/pag-2.svg);
      width: 10px;
      height: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pagImg-3 {
      background-image: url(./../../../media/images/icons/pag-3.svg);
      width: 10px;
      height: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--pagPag {
      width: 8px;
      height: 8px;
      background-color: #62c960;
      border-radius: 50%;
    }

    &--wrapper {
      background-image: url(./../../../media/images/bg_tb-2-6.svg);
      background-size: 112px 480px;
      background-position: 48px 44px;
      background-repeat: no-repeat;
    }
  }
}

.table__col-1-row-1,
.table__col-1-row-2,
.table__col-1-row-3,
.table__col-1-row-4,
.table__col-1-row-5,
.table__col-1-row-6,
.table__col-1-row-7,
.table__col-1-row-8,
.table__col-1-row-9,
.table__col-1-row-10,
.table__col-1-row-11 {
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  &--text {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #292e33;
  }
}

.table__col-2-row-1,
.table__col-2-row-2,
.table__col-2-row-3,
.table__col-2-row-4,
.table__col-2-row-5,
.table__col-2-row-6,
.table__col-2-row-7,
.table__col-2-row-8,
.table__col-2-row-9,
.table__col-2-row-10,
.table__col-2-row-11 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 48px;

  &--text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #292e33;
  }

  &--line-1 {
    height: 2px;
    background-color: #d7bd97;
    margin-bottom: 2px;
    border-radius: 2px;
  }

  &--line-2 {
    width: 160px;
    border-radius: 2px;
    height: 2px;
    background-color: #c19c65;
  }
}

.table__col-3-row-1,
.table__col-3-row-2,
.table__col-3-row-3,
.table__col-3-row-4,
.table__col-3-row-5,
.table__col-3-row-6,
.table__col-3-row-7,
.table__col-3-row-8,
.table__col-3-row-9,
.table__col-3-row-10,
.table__col-3-row-11,
.table__col-4-row-1,
.table__col-4-row-2,
.table__col-4-row-3,
.table__col-4-row-4,
.table__col-4-row-5,
.table__col-4-row-6,
.table__col-4-row-7,
.table__col-4-row-8,
.table__col-4-row-9,
.table__col-4-row-10,
.table__col-4-row-11,
.table__col-5-row-1,
.table__col-5-row-2,
.table__col-5-row-3,
.table__col-5-row-4,
.table__col-5-row-5,
.table__col-5-row-6,
.table__col-5-row-7,
.table__col-5-row-8,
.table__col-5-row-9,
.table__col-5-row-10,
.table__col-5-row-11,
.table__col-6-row-1,
.table__col-6-row-2,
.table__col-6-row-3,
.table__col-6-row-4,
.table__col-6-row-5,
.table__col-6-row-6,
.table__col-6-row-7,
.table__col-6-row-8,
.table__col-6-row-9,
.table__col-6-row-10,
.table__col-6-row-11 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 48px;

  &--text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #292e33;
  }

  &--line-1 {
    height: 2px;
    background-color: #62c960;
    margin-bottom: 2px;
    border-radius: 2px;
  }

  &--line-2 {
    width: 160px;
    border-radius: 2px;
    height: 2px;
    background-color: #c19c65;
  }
}

.table__col-2-row-1 {
  &--line-1 {
    width: 160px;
  }
}

.table__col-2-row-2 {
  &--line-1 {
    width: 151px;
  }
}

.table__col-2-row-3 {
  &--line-1 {
    width: 142px;
  }
}

.table__col-2-row-4 {
  &--line-1 {
    width: 132px;
  }
}

.table__col-2-row-5 {
  &--line-1 {
    width: 123px;
  }
}

.table__col-2-row-6 {
  &--line-1 {
    width: 114px;
  }
}

.table__col-2-row-7 {
  &--line-1 {
    width: 105px;
  }
}

.table__col-2-row-8 {
  &--line-1 {
    width: 96px;
  }
}

.table__col-2-row-9 {
  &--line-1 {
    width: 86px;
  }
}

.table__col-2-row-10 {
  &--line-1 {
    width: 77px;
  }
}

.table__col-2-row-11 {
  &--line-1 {
    width: 68px;
  }
}

.table__col-3-row-1 {
  &--line-1 {
    width: 136px;
  }
}

.table__col-3-row-2 {
  &--line-1 {
    width: 127px;
  }
}

.table__col-3-row-3 {
  &--line-1 {
    width: 118px;
  }
}

.table__col-3-row-4 {
  &--line-1 {
    width: 108px;
  }
}

.table__col-3-row-5 {
  &--line-1 {
    width: 99px;
  }
}

.table__col-3-row-6 {
  &--line-1 {
    width: 90px;
  }
}

.table__col-3-row-7 {
  &--line-1 {
    width: 81px;
  }
}

.table__col-3-row-8 {
  &--line-1 {
    width: 72px;
  }
}

.table__col-3-row-9 {
  &--line-1 {
    width: 62px;
  }
}

.table__col-3-row-10 {
  &--line-1 {
    width: 53px;
  }
}

.table__col-3-row-11 {
  &--line-1 {
    width: 44px;
  }
}

.table__col-4-row-1 {
  &--line-1 {
    width: 128px;
  }
}

.table__col-4-row-2 {
  &--line-1 {
    width: 119px;
  }
}

.table__col-4-row-3 {
  &--line-1 {
    width: 110px;
  }
}

.table__col-4-row-4 {
  &--line-1 {
    width: 101px;
  }
}

.table__col-4-row-5 {
  &--line-1 {
    width: 91px;
  }
}

.table__col-4-row-6 {
  &--line-1 {
    width: 82px;
  }
}

.table__col-4-row-7 {
  &--line-1 {
    width: 73px;
  }
}

.table__col-4-row-8 {
  &--line-1 {
    width: 64px;
  }
}

.table__col-4-row-9 {
  &--line-1 {
    width: 54px;
  }
}

.table__col-4-row-10 {
  &--line-1 {
    width: 45px;
  }
}

.table__col-4-row-11 {
  &--line-1 {
    width: 36px;
  }
}

.table__col-5-row-1 {
  &--line-1 {
    width: 120px;
  }
}

.table__col-5-row-2 {
  &--line-1 {
    width: 110px;
  }
}

.table__col-5-row-3 {
  &--line-1 {
    width: 102px;
  }
}

.table__col-5-row-4 {
  &--line-1 {
    width: 92px;
  }
}

.table__col-5-row-5 {
  &--line-1 {
    width: 83px;
  }
}

.table__col-5-row-6 {
  &--line-1 {
    width: 74px;
  }
}

.table__col-5-row-7 {
  &--line-1 {
    width: 65px;
  }
}

.table__col-5-row-8 {
  &--line-1 {
    width: 56px;
  }
}

.table__col-5-row-9 {
  &--line-1 {
    width: 46px;
  }
}

.table__col-5-row-10 {
  &--line-1 {
    width: 37px;
  }
}

.table__col-5-row-11 {
  &--line-1 {
    width: 28px;
  }
}

.table__col-6-row-1 {
  &--line-1 {
    width: 112px;
  }
}

.table__col-6-row-2 {
  &--line-1 {
    width: 103px;
  }
}

.table__col-6-row-3 {
  &--line-1 {
    width: 94px;
  }
}

.table__col-6-row-4 {
  &--line-1 {
    width: 85px;
  }
}

.table__col-6-row-5 {
  &--line-1 {
    width: 75px;
  }
}

.table__col-6-row-6 {
  &--line-1 {
    width: 66px;
  }
}

.table__col-6-row-7 {
  &--line-1 {
    width: 57px;
  }
}

.table__col-6-row-8 {
  &--line-1 {
    width: 48px;
  }
}

.table__col-6-row-9 {
  &--line-1 {
    width: 38px;
  }
}

.table__col-6-row-10 {
  &--line-1 {
    width: 29px;
  }
}

.table__col-6-row-11 {
  &--line-1 {
    width: 20px;
  }
}

.dumbbell {
  &__one,
  &__two,
  &__three,
  &__four,
  &__five,
  &__six,
  &__seven,
  &__eight,
  &__nine,
  &__ten,
  &__eleven {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left {
      width: 8px;
      height: 8px;
      background-color: #62c960;
      border-radius: 50%;
    }

    &--midle {
      height: 1px;
      background-color: #c2c7cc;
      width: 50px;
    }

    &--right {
      width: 8px;
      height: 8px;
      background-color: #62c960;
      border-radius: 50%;
    }
  }

  &__one {
    width: 69px !important;

    &--midle {
      width: 50px;
    }
  }

  &__two {
    width: 78px !important;

    &--midle {
      width: 57px;
    }
  }

  &__three {
    width: 87px !important;

    &--midle {
      width: 66px;
    }
  }

  &__four {
    width: 96px !important;

    &--midle {
      width: 76px;
    }
  }

  &__five {
    width: 106px !important;

    &--midle {
      width: 85px;
    }
  }

  &__six {
    width: 115px !important;

    &--midle {
      width: 94px;
    }
  }

  &__seven {
    width: 120px !important;

    &--midle {
      width: 103px;
    }
  }

  &__eight {
    width: 133px !important;

    &--midle {
      width: 112px;
    }
  }

  &__nine {
    width: 143px !important;

    &--midle {
      width: 122px;
    }
  }

  &__ten {
    width: 152px !important;

    &--midle {
      width: 131px;
    }
  }

  &__eleven {
    width: 161px;

    &--midle {
      width: 136px;
    }
  }
}
