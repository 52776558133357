.table {
  display: flex;
  justify-content: space-between;
  margin-bottom: 135px;
  overflow: scroll;

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px;
    width: 194px;
    height: 240px;
  }

  &__card--blue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px;
    width: 194px;
    height: 240px;
    border: 1px solid #005baa;
    border-radius: 2px;
    position: relative;

    &::after {
      content: "Оптимальна швидкість";
      position: absolute;
      color: #005baa;
      width: 157px;
      height: 55px;
      font-size: 16px;
      font-weight: 700;
      bottom: -65px;
    }
  }
}
.card__clock-1,
.card__clock-2,
.card__clock-3,
.card__clock-4,
.card__clock-5,
.card__clock-6 {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
}
.card {
  &__clock-1 {
    background-image: url(../../../media/images/icons/sowing_table4/clock-1.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 64px;
    height: 64px;
  }
  &__clock-2 {
    background-image: url(../../../media/images/icons/sowing_table4/clock-2.svg);
  }
  &__clock-3 {
    background-image: url(../../../media/images/icons/sowing_table4/clock-3.svg);
  }
  &__clock-4 {
    background-image: url(../../../media/images/icons/sowing_table4/clock-4.svg);
  }
  &__clock-5 {
    background-image: url(../../../media/images/icons/sowing_table4/clock-5.svg);
  }
  &__clock-6 {
    background-image: url(../../../media/images/icons/sowing_table4/clock-6.svg);
  }

  &__time,
  &__time--blue {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #ed1b28;
  }
  &__time--blue {
    color: #005baa;
  }

  &__speed {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #292e33;
  }

  &__range {
    width: 160px;
  }
  &__dumbell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #676d73;
    }

    &--left-bl {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #292e33;
    }

    &--line {
      width: 140px;
      height: 2px;
      background-color: #c2c7cc;
    }

    &--line-bl {
      width: 140px;
      height: 2px;
      background-color: #676d73;
    }

    &--right {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #676d73;
    }

    &--right-bl {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #292e33;
    }
  }

  &__text {
    width: 100%;
    font-size: 12px;
    color: #292e33;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
  }
}
