.titleContainer {
  display: flex;
  // flex-wrap: wrap;
  postion: relative;
  align-items: center;
  margin-bottom: 55px;

  .Wrapper {  
    position: relative;
 

    .content {
      display: flex;
      // justify-content: space-between;
      // width: 100%;
      


      p {
        color: var(--4, #292e33);
        font-family: Open Sans;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        margin-right: 20px;

        span {
          color: rgba(194, 199, 204, 1);
          font-weight: 400;
        }

        sup {
          font-family: Open Sans;
          font-size: 24px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: rgba(194, 199, 204, 1);
        }

        &::before {
          content: ".";
          color: black;
          position: absolute;

          top: -25px;
          left: 0;


        }
      }

      h2 {
        color: var(--4, #292e33);
        font-family: Open Sans;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        max-width: 450px;
        // width: 450px;
        margin-right: 40px;
 
        // overflow-wrap: break-word;


        @media (max-width: 768px) {
          font-size: 38px;
        }
      }

      .decorArrowWrapper {
        padding-top: 15px;
     

        @media (max-width: 768px) {
    display:  none;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
            }
      
    }

    .afterContainer {
      width: 100%;
      height: 100%;

      &::before {
        position: absolute;
        top: 75%;
        right: 0;
        content: "";
        display: block;
        border-top: 2px solid rgba(194, 199, 204, 1);
        width: 8%;

        
        @media (max-width: 768px) {
          display:  none;
              }
      }
    }
  }


  @media (max-width: 768px) {
    // flex-direction: column;
        }
}

// .wrap__text {
//   display: flex;
//   margin-right: 200px;
// }