.wrapper {
padding-top: 30px;


    p {
        font-family: Open Sans;
        font-size: 32px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(242, 242, 243, 1);
        margin-bottom: 30px;
        
    }

    h2 {
        font-family: Open Sans;
        font-size: 40px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 30px;
        
    }

    h3 {
        font-family: Open Sans;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
        
    }
}