.period {
    margin-bottom: 4px;

    &__number {
        display: inline-flex;
        align-items: center;
        position: relative;
        bottom: -10px;
        left: -2px;

        p.default {
            color: #C2C7CC;
            font-size: 14px;
            line-height: 24px;
        }
        p.low { 
            color: #292E33;
            font-size: 15px;
            line-height: 24px;
        }
        p.main { 
            color: #292E33;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }
    }

    &__number.low {
        left: -6px;
        gap: 3px;
    }
    &__number.main {
        left: -8px;
        gap: 4px;
    }

    &__content{
        position: absolute;
        left: 102%;
        max-width: 395px;
        width: 100%;

        svg {
            margin-left: 10px;
            cursor: pointer;

            path {
                transition: all 0.3s;
            }
            
            &:hover {
                path {
                    fill: #292E33;
                }
            }
        }
    }
}

.period__content {
    h3 {
        color: #292E33;
    }
    &-inner {
        &:last-child {
            margin-top: 30px;
        }
    }
}