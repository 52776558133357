@media (max-width: 600px) {
}
@media (max-width: 440px) {
}

.gasprodContainer {
  --color-dark-black: #292e33;
  --background-color-grey: #dae0e5;
  --border-color-grey: #c2c7cc;
  --color-div-svg: #33363f;
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  margin-right: 120px;
  box-sizing: border-box;
  margin-top: 30px;
  @media (max-width: 470px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  &__innerContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // border-top-style: solid;
    // border-top-width: 1px;
    // border-top-color: var(--border-color-grey);
    margin-top: 40px;
    margin-bottom: 60px;
    /////////////////////////////
    padding-top: -1px;

    &__section1 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 20px;
      // margin-bottom: 10px;

      h1 {
        width: 46px;
        font-weight: 700;
        font-size: 46px;
        line-height: 16px;
        display: block;
        // margin-top: 40px;
        margin-top: 20px;
        ///////////////////////////////////////////////////
      }
      p {
        width: 402px;
        height: 57px;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
      }
    }

    &__section2 {
      width: 448px;
      //   width: 40%;
      height: 80px;
      // background-color: var(--background-color-grey);
      background-color: white;
      display: flex;
      align-items: center;
      position: relative;
      border: 1px solid var(--background-color-grey);
      border-radius: 4px;

      &:hover {
        background-color: #f0f1f6;
      }
      @media (max-width: 600px) {
        width: 320px;
        height: 85px;
      }
      p {
        margin-left: 16px;
        //////////////////////////
        width: 70%;
        @media (max-width: 600px) {
          width: 85%;
        }
        a {
          text-decoration: none;
          color: var(--color-dark-black);
        }
      }
    }
    &__section3 {
      p {
        font-weight: 400;
        font-size: 16px;
        width: 60%;
        margin-top: 10px;
        margin-bottom: 20px;
        ////////////////////////////////////////
        display: flex;
        gap: 10px;
        span {
          display: block;
          font-weight: 700;
        }

        //////////////////////////////////////////
        @media (max-width: 600px) {
          width: 95%;
        }
        a {
          text-decoration: none;
          color: var(--color-dark-black);
          &:hover {
            font-weight: 500;
          }
        }
      }
    }
  }
}
.p4 {
  margin-top: 15px;
}
.div__h1 {
  // border-top-style: solid;
  // border-top-width: 5px;
  // border-top-color: var(--border-color-grey);
}

.div__svg {
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--color-div-svg);
}
.div__svg__arrow {
  position: absolute;
  right: 10px;
  // top: 50px;
  bottom: 30%;
  color: var(--color-div-svg);
}
