.soil {
    margin-top: 100px;
    h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        color: #292E33;
    }

    .light {
        color: #C19C65;
        font-weight: 700;
    }
    .middle {
        color: #845713;
        font-weight: 700;
    }
    .hard {
        color: #292E33;
        font-weight: 700;
    }

    .table__inner {
        overflow-x: auto;
        table {
            min-height: 746px;
        }
    }

    .table__description {
        margin-bottom: 70px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        &-item {
            display: flex;
            gap: 16px;
            align-items: center;

            span.gray {
                color: #676D73;
            }
        }
    }

    .table-link {
        font-weight: 400;
        line-height: 24px;
        color: #676D73;
        margin: 10px 0 30px 0;
        display: inline-block;
    }

    &__descr {
        max-width: 576px;
        padding: 16px 16px 0 16px;
        border: 1px solid #FFFFFF;
        border-radius: 2px;
        margin-bottom: 100px;
        counter-reset: countP;

        p {
            margin-bottom: 16px;
            position: relative;
            
            &::before{
                counter-increment: countP;
                position: absolute;
                content: counter(countP, decimal-leading-zero);
                left: -40px;
                top: 0;
                color: #C2C7CC;
                font-size: 14px;
              }    
        }

        span.gray {
            color: #C2C7CC;
        }
        span.black {
            color: #292E33;
            font-weight: 700;
        }
    }

    &__vapn {
        
        h3 {
            max-width: 730px;
        }
    }

    &__prepare {
        h3 {
            max-width: 540px;
        }
    }

    &__prepare-table {
        display: flex;
        flex-wrap: wrap;
        gap: 80px;
        margin: 40px 0 80px 0;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            p {
                &:first-child{
                    color: #845713;
                    font-size: 40px;
                    font-weight: 700;
                    line-height: 24px;
                    margin-bottom: 30px;
                }
                &:last-child {
                    max-width: 160px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }

}