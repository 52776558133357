.wrrapperInfo {
  .wrappertitle {
    position: relative;

    h2 {
      font-family: Open Sans;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      
      text-align: left;
      color: rgba(0, 91, 170, 1);
      margin-bottom: 40px;
      max-width: 500px;

      &::before {
        position: absolute;
        content: "";
        top: -15px;
        left: 0px;
        // border-top: 4px solid rgba(0, 91, 170, 1);
        width: 5%;
      }

      @media (max-width: 768px) {
       color: black;
       }
    }

    .decor__info {
      margin-bottom: 16px;
      display: flex;

      font-family: Open Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(103, 109, 115, 1);

      .decor__date {
        width: 130px;
        min-width: 130px;
        // border: 1px solid black;
      }

      .decor__time {
        width: 160px;
        // border: 1px solid rgb(202, 63, 63);
      }

      .decor__theme {
        // border: 1px solid rgb(40, 149, 80);
        // width: 100%;
        width: 500px;
        margin-right: auto;
      }

      .decor__lecture {
        width: 200px;
        min-width: 200px;
        // border: 1px solid rgb(106, 78, 172);
      }

      @media (max-width: 768px) {
       display: none;
      }
    }
  }

  .wrrapperInfo__byDate {

    // background-color: green;


    .list__byDate {
      // padding-top: 15px;
      padding-bottom: 120px;
      
      .item__byDate {
        display: flex;
        align-items: baseline;
        border-top: 1px solid rgba(194, 199, 204, 1);
        // border-top: 1px solid tomato;
        padding-top: 18px;

        
        
        
        .text__byDate {
          display: flex;
          justify-content: space-between;
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          width: 130px;
          min-width: 130px;
          cursor: pointer;

          .decor__arrowLeft {
            display: none;
            @media (max-width: 768px) {
              // display: none;
            } 
          }

          .decor__arrowRight {
            // display: none;
            @media (max-width: 768px) {
              // display: none;
            } 
          }
          

          @media (max-width: 768px) {
            color: black;
            background-color: width(249, 241, 241);
            width: 100%;
            padding: 10px 10px 10px 10px;
          }  
        }

        .text__byDate--active {
          display: flex;
          justify-content: space-between;
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          width: 130px;
          min-width: 130px;
          cursor: pointer;

          .decor__arrowLeft {
            display: none;
            @media (max-width: 768px) {
              display: block;
            } 
          }

          .decor__arrowRight {
            // display: none;
            @media (max-width: 768px) {
              display: block;
            } 
          }

          @media (max-width: 768px) {
            color: white;
            background-color: black;
            width: 100%;
            padding: 10px 10px 10px 10px;
          } 
      }


        
        
        .list__byTime {
          // display: flex;
          width: 100%;

          .item__byTime {
            display: flex;
            // width: 100%;
            align-items: baseline;
            // margin-bottom: 30px;
            // justify-content: center;

            .time__byTime {
              font-family: Open Sans;
              font-size: 16px;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              // margin-right: 60px;
              width: 160px;

              @media (max-width: 768px) {
                margin-bottom: 20px;
              }
            }

            .description__byTime {
              font-family: Open Sans;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              max-width: 500px;
              margin-right: auto;
              @media (max-width: 768px) {
                margin-bottom: 20px;
              }
            }

            .lecture__byTime {
              font-family: Open Sans;
              font-size: 16px;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              width: 200px;
              width: 200px;
            }



            @media (max-width: 768px) {
              flex-direction: column;

              border-top: 1px solid rgba(194, 199, 204, 1);
              padding: 27px 8px 27px 8px;
            }
          }


          @media (max-width: 768px) {
          
          }
        }


      

          @media (max-width: 768px) {
            flex-direction: column;
              padding-top: 0;

          }


          @media (max-width: 768px) {
            margin-bottom: 16px;
            border: 1px solid rgba(194, 199, 204, 1);
       
           }

      }
    }






  }
}

.decor__text {
  display: none;
  font-family: Open Sans;
font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: rgba(103, 109, 115, 1);
margin-bottom: 8px;



  @media (max-width: 768px) {
    display: block;
  }
}