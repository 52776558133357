.LectureWrapper {
  padding-top: 75px;
  padding-bottom: 125px;
  display: flex;

  .LectureList {
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    // width: 100%;
    // border: 1px solid green;
 

    li {
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  // @media (max-width: 768px) {
  //   width: 100%;
  // }

}