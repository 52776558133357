.location__list {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 100px;
  padding-top: 10px;

  .active p {
    font: bold 20px Open Sans;

  }

  .active::after {
    transform: scale(1, 1);
    opacity: 1;
    background-color: rgba(0, 91, 170, 1);
    @media (max-width: 768px) {
      background-color: black;

   
     }
  }

  li {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding-bottom: 10px;
    color: rgba(0, 91, 170, 1);

    // background-color: tomato;

    p {
      width: 160px;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;


      
    @media (max-width: 768px) {
      color: black;
      // font: bold 30px Arial, sans-serif;

   
     }
    }

    &:not(:last-child) {
      margin-right: 32px;
    }

    &::after {
    // background-color: tomato;
      position: absolute;
      left: 0;
      top: -10px;
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      // background-color: transparent;
      border-radius: 2px;
      opacity: 0;
      transform: scale(0, 0);
      transform-origin: bottom left;
      transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover::after,
    &:focus::after {
      background-color: rgba(0, 91, 170, 1);
      opacity: 1;
      transform: scale(1, 1);

      @media (max-width: 768px) {
        background-color: black;
        
     
       }
    }

    span {
      font-family: Open Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }


    @media (max-width: 768px) {
     margin-bottom: 15px;
    //  background-color: black;
  
    }
  }


  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

  }
}
