@import "./../../styles/mixins.scss";
@import "./../../styles/variables.scss";

.tech {
  padding: 112px 0 112px 0;

  h2, img {
    padding-bottom: 24px;
  }

  img {
    border-radius: 2px;
  }
}