.crop-scheme-item {
    position: relative;

    svg.measur {
        position: absolute;
        bottom: 3%;
        left: 0;
        z-index: -1;
    }

    &__descr {
        position: absolute;
        left: 20%;
        top: 40%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        max-width: 100px;
        height: 100px;

        p {
            font-weight: 600;
            line-height: 16px;
            font-size: 16px;

            span {
                font-weight: 400;
            }
        }
    }
}

svg.glue~.crop-scheme-item__descr { 
    top: 50%;
    height: 40px;
}

.question {
    cursor: pointer;

    svg {
        position: absolute;
        right: -10%;
        top: 0;
    }

    .hint {
        position: absolute;
        cursor: pointer;
        right: -120%;
        top: -15%;
        opacity: 0;
        clip: rect(0,0,0,0);
        transition: all 0.3s;
    }

    &:hover {
        .hint {
            opacity: 1;
            clip: auto;
        }
    }
}