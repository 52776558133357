.question {
  background-color: transparent;
  width: 100%;
  padding: 0 120px;
  // height: 100vh;
  form {
    max-height: 365px;
  }

  &__title {
    text-transform: uppercase;
    font-family: 'Rubik Mono One', sans-serif;
    font-size: 60px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 50px;
  }

  &__form {
    width: 100%;
    display: flex;
    gap: 32px;
  }

  &__left {
    display: flex;
    flex-direction: column;
  }

  &__right {
    display: flex;
    flex-direction: column;
  }

  &__text {
    margin-top: 20px;
    width: 176px;
    color: rgba(255,255,255, 0.7);
    font-size: 12px;
  }

  &__input {
    height: 40px;
    width: 256px;
    padding-left: 16px;
    font-size: 16px;
    font-weight: "Open Sans", sans-serif;
    background-color: transparent;
    outline: none;
    border: 1px solid rgba(255,255,255, 0.5);
    border-radius: 2px;
    margin-bottom: 8px;
    color: #fff;

    &--text {
      padding: 16px;
      height: 136px;
      width: 352;
      padding-left: 16px;
      font-size: 16px;
      font-weight: "Open Sans", sans-serif;
      background-color: transparent;
      outline: none;
      border: 1px solid rgba(255,255,255, 0.5);
      margin-bottom: 8px;
      color: rgba(255,255,255, 0.7);

      &::placeholder {
        color: #fff;
        font-size: 16px !important;
      }
    }

    &:nth-child(3n) {
      margin-bottom: 32px;
    }

    &::placeholder {
      color: #fff;
    }
  }
}

textarea {
  padding: 16px;
  height: 136px;
  padding-left: 16px;
  font-size: 16px;
  font-weight: "Open Sans", sans-serif;
  background-color: transparent;
  outline: none;
  border: 1px solid rgba(255,255,255, 0.5);
  margin-bottom: 8px;
  color: #fff;
  resize: none;
  width: 100%;
  max-width: 400px;

  &::placeholder {
    color: #fff;
    font-size: 16px !important;
  }
}

@media (max-width: 1050px) { 
  .question {
    padding: 0;
  }

  textarea {
    max-width: 325px;
  }

}

@media (max-width: 660px) { 

  .question__form {
    flex-wrap: wrap;
  }
  
  .question {
    form {
      max-height: 100%;
    }
  }
}