.LogisticsWrapper {
  // position: relative;
  // background-color: red;
  // overflow: hidden;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}

.ContentLogisticsWrapper {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  // width: 300vh;

  img {
    position: absolute;
    right: 0px;
    top: 100px;
    width: 50%;
    height: 50%;

    
@media (max-width: 768px) {
  position: static;
  float: right;
}
  }
}

.containerList {
  max-width: 440px;

  .description {
    color: var(--4, #292e33);
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 22px;
  }

  li {
    margin-bottom: 30px;
    p {
      display: flex;
      color: var(--4, #292e33);
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 20px;

      strong {
        padding-left: 25px;
      }
    }

    .itemWrapper {
      display: flex;

      .itemIcon {
        margin-right: 14px;
      }
    }
  }

  .descriptionFinish {
    padding: 14px 16px 14px 16px;
    border: 1px solid rgba(255, 255, 255, 1);
  }
}




