.mapWrapper {
  position: relative;
  width: 100%;
  max-height: 350px;

  iframe {

    width: 100%;
    height: 100%;
    border: 0;

  }
}