.wrapperList {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 25%;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #c2c7cc;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-right: 32px;

    &:not(:last-child) {
      margin-bottom: 16px; 
    }

    a {
      display: flex;
      align-self: center;
      color: #c2c7cc;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
    }



    @media (max-width: 1360px) {

    }
  
    @media (max-width: 768px) {
      // background-color: tomato;
      // border: 1px solid tomato;
      width: 100%;
      padding: 0;
    }


  }

  @media (max-width: 1360px) {

  }

  @media (max-width: 768px) {
    margin-bottom: 56px;
    width: 100%;
    // display: none; 
  }
}
