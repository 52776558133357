.calculate-soil {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;

    p {
        max-width: 400px;
        margin-bottom: 30px;
        span {
            color: #292E33;
        }
    }

    span {
        font-weight: 700;
        color: #676D73;
    }

    &__inner {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    input {
        font-family: Open Sans;
        color: #676D73;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        padding: 5px 15px;
        max-width: 120px;
    }
}