.contactsWrapper {
  display: flex;
  flex-wrap: wrap;


  .iconTitle {
    fill: rgba(194, 199, 204, 1);
    margin-bottom: 8px;
    margin-right: 280px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  ul {
    // background-color: tomato;
    li {

      display: flex;
      margin-bottom: 16px;
      a {
        .contacts_phone__decor0 {
          color: var(--7, #676d73);
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        .contacts_phone__decor1 {
          color: var(--5, #c2c7cc);
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        .contacts_phone__decor2 {
          color: var(--5, #c2c7cc);
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }

        .textDecorLocation {
          color: var(--5, #c2c7cc);
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }

        .textDecorWord {
          color: #45494d;
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          // margin-right: auto;
        }

        .email_decor {
          color: #c2c7cc;
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }

        .email_decorAddress {
          color: #676d73;
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }

        @media (max-width: 768px) {
          margin-bottom: 32px;
          display: flex;
          
        }
      }

      button {
        background-color: transparent;
        border: none;
        margin-left: 10px;
        transition: color 500ms linear;
        cursor: pointer;
        padding: 0;
        width: 16px;
        height: 16px;

        .iconCopy {
          fill: rgba(69, 73, 77, 1);

          &:hover {
            fill: white;
          }
        }
      }
    }

    @media (max-width: 768px) {
      margin-bottom: 64px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;


  }
}

.decor__mobile {
  display: none;
  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    display: inline-block;
    fill: rgba(103, 109, 115, 1);
    width: 16px;
    height: 16px;
  }
}

.wrapperList__phone {
  display: flex;
}

.number {
  display: block;
}

.location__link {
  min-width: 300px;
}

.el__phone {
  min-width: 300px;
}

.number {
  white-space: nowrap;
}

.email__link {
  white-space: nowrap;
}

.location__list {
  flex: 1;
  // margin-right: 32px;

  @media (max-width: 768px) {
    width: auto;
    margin-right: 0px;
  }
}

.location__element {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.phone__list {
  @media (max-width: 768px) {
    height: 6em;
    overflow: hidden;
  }
}

.wrapper__location {
  flex: 1;
}

.wrapper__phone {
  flex: 0.5;
}

.wrapper__email {
  min-width: 300px;
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
}


.wrapper__iconLocation {
  display: flex;
}