.servicesListWrapper {
  display: flex;
  max-width: 1920px;
  border-bottom: 1px solid #292e33;
  padding-top: 88px;
  padding-bottom: 54px;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 88px;
    padding-bottom: 54px;
  }
}

.servicesCard {
  width: 25%;

  &:not(:last-child) {
    padding-right: 32px;
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    width: 100%;

    &:not(:last-child) {
      padding-right: 0;
      margin-bottom: 54px;
    }
  }

  h2 {
    margin-bottom: 18px;
    color: var(--unnamed, #fff);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 4px;

    span {
      position: relative;
      color: #676d73;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &::before {
        content: "";
        position: absolute;

        top: -4px;
        left: 0;
        right: 0;
        height: 1px;
        width: 80%;
        background-color: #676d73;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #c2c7cc;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .a {
    color: #c2c7cc;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
  }
}
