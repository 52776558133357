@import "./../../styles/mixins.scss";
@import "./../../styles/variables.scss";



.vacancies {
  padding: 112px 0 112px 0;

  @include onTab {
    padding: 50px 0 154px;
  }

  .resume-inner {
    margin-top: 50px;
    button[type="submit"] {
      background-color: #292e33;
      &:hover {
        background-color: #343a41;
      }
    }
    input[type="file"] {
      color: #292e33;
      padding: 0;
      margin-bottom: 10px;
      border: 0;

      &::file-selector-button {
        font-weight: bold;
        color: #292e33;
        padding: 10px;
        margin-right: 10px;
        border: thin solid grey;
        border-radius: 3px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          color: #fff;
          background-color: #292E33;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    gap: 32px;
  }

 &__wrapper.acord {
    margin-bottom: 100px;

    .acord__item,
    .acord__content {
      width: 100%;
    }

    .acord__item {
      @media (max-width: 1000px) {
        font-size: 18px;
      }
    }
 }

  &__list {
    width: 100%;
  }

  &__item {
    position: relative;
    padding-left: 15px;
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #c2c7cc;

    &--number {
      font-size: 32px;
      color: #676d73;
      margin-right: 15px;
    }

    &--text {
      font-size: 16px;
      color: #292e33;
    }

    &--text.active {
      color: #fff;
      font-weight: 700;
    }

    &::after {
      content: "";
      position: absolute;
      width: 32px;
      height: 10px;
      right: 0;
      background-image: url('../../media/images/icons/arr-long-left.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__item.active {
    background-color: #292e33;

    &::after {
      content: "";
      position: absolute;
      width: 64px;
      height: 13px;
      right: -20px;
      background-image: url('../../media/images/icons/arr-long-right.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__content--phone.active {
    display: none !important; 

    @include onTab {
      display: block !important; 
    }
  }

  &__content--desktop.active {
    display: block !important;

    @include onTab {
      display: none !important;
    }
  }

  &__content {
    width: 100%;
    border: 1px solid #c2c7cc;
    border-radius: 2px;
    padding: 22px 32px 56px 56px;
    font-size: 16px;
    color: #292e33;

    @include onTab {
      
    padding: 22px 15px 56px 15px;
      width: 272px;
    }
  }

  &__content-education {
    &--title {
      position: relative;
      font-size: 16px;
      font-weight: 700;
      color: #292e33;
      margin-bottom: 6px;

      &::before {
        content: url(./../../media/images/icons/education.svg);
        position: absolute;
        width: 24px;
        height: 24px;
        left: -30px;
      }
    }

    &--content {
      font-weight: 400;
      font-size: 16px;
      color: #292e33;
      padding-bottom: 40px;
    }
  }

  &__content-requirements {
    &--title {
      position: relative;
      font-size: 16px;
      font-weight: 700;
      color: #292e33;
      margin-bottom: 6px;

      &::before {
        content: url(./../../media/images/icons/award.svg);
        position: absolute;
        width: 24px;
        height: 24px;
        left: -30px;
      }
    }

    &--content {
      font-weight: 400;
      font-size: 16px;
      color: #292e33;
      padding-bottom: 40px;
    }
  }

  &__content-tasks {
    &--title {
      position: relative;
      font-size: 16px;
      font-weight: 700;
      color: #292e33;
      margin-bottom: 6px;
      &::before {
        content: url(./../../media/images/icons/req.svg);
        position: absolute;
        width: 24px;
        height: 24px;
        left: -30px;
      }
    }

    &--content {
      font-weight: 400;
      font-size: 16px;
      color: #292e33;
      padding-bottom: 40px;
    }
  }

  &__content-conditions {
    &--title {
      position: relative;
      font-size: 16px;
      font-weight: 700;
      color: #292e33;
      margin-bottom: 6px;
      &::before {
        content: url(./../../media/images/icons/cond.svg);
        position: absolute;
        width: 24px;
        height: 24px;
        left: -30px;
      }
    }

    &--content {
      font-weight: 400;
      font-size: 16px;
      color: #292e33;
      padding-bottom: 40px;
    }
  }



  &__subtitle {
    max-width: 448px;
    font-size: 16px;
    font-weight: 400;
    color: $vacanciesContentColor;
    margin-bottom: 50px;

    @include onTab {
      margin-bottom: 40px;
    }
  }
}

.vac {
  &__title {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    color: $vacTitleColor;
    margin-bottom: 6px;

    @include onTab {
      right: -24px;
      max-width: calc(100% - 24px);
    }

    &--education {
      &::before {
        content: url(./../../media/images/icons/education.svg);
      }
    }

    &--requirements {
      &::before {
        content: url(./../../media/images/icons/award.svg);
      }
    }

    &--tasks {
      &::before {
        content: url(./../../media/images/icons/req.svg);
      }
    }

    &--conditions {
      &::before {
        content: url(./../../media/images/icons/cond.svg);
      }
    }

    &::before {
      position: absolute;
      width: 24px;
      height: 24px;
      left: -30px;
    }
  }

  &__content {
    font-weight: 400;
    font-size: 16px;
    color: $vacContentColor;
    // padding-bottom: 40px;
  }
}

.people {
  display: flex;
  flex-wrap: wrap;

  &__card {
    flex: 0 0 25%;
    height: 305px;
    margin-bottom: 110px;

    @include onTV {
      margin-bottom: 96px;
    }

    &--img-1 {
      background-image: url(./../../media/images/employees/lubov.png);
    }

    &--img-2 {
      background-image: url(./../../media/images/employees/petro.png);
    }
    &--img-3 {
      background-image: url(./../../media/images/employees/lidia.png);
    }
    &--img-4 {
      background-image: url(./../../media/images/employees/misha.png);
    }
    &--img-5 {
      background-image: url(./../../media/images/employees/ira.png);
    }
    &--img-6 {
      background-image: url(./../../media/images/employees/misha2.png);
    }

    &--img {
      position: relative;
      display: block;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 11px;

      &::after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #292E33;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 20%;
      }

      @include onTV {
        width: 200px;
        height: 200px;

        &::after {
          width: 20px;
          height: 20px;
        }
      }
    }

    &--name {
      font-size: 24px;
      color: #292e33;
      font-weight: 400;
      margin-bottom: 11px;
    }

    &--surname {
      font-weight: 700;
    }

    &--phone {
      max-width: 240px;
      position: relative;
      font-size: 14px;
      color: #292e33;
      font-weight: 600;
      margin-bottom: 11px;

      &::after {
        content: url(./../../media/images/icons/copy.svg);
        position: absolute;
        top: 0;
        right: 80px;
      }
    }

    &--email {
      max-width: 240px;
      position: relative;
      font-size: 14px;
      color: #292e33;
      font-weight: 600;
      margin-bottom: 37px;

      &::after {
        content: url(./../../media/images/icons/copy.svg);
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    &--line {
      width: 16px;
      height: 2px;
      background-color: #c2c7cc;
    }
  }
}

.list_CardEngineer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
}