
  .title {
    display: flex;
    position: relative;
    margin-bottom: 48px;
    gap: 10px;

    span {
      color: #C2C7CC; 
      font-weight: 300;
      font-size: 24px; 
      display: inline-block; 
      position: relative; 
      top: -15px; 
    }
  }

  .decorArrowWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    margin-left: 20px;
  }

  .number {
    position: relative;
    font-size: 48px;
    font-weight: 700;
    color: #292e33;
    margin-right: 10px;
  }

  .text {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 5%;
    color: #292e33;
    text-transform: uppercase;
  }

  @media (max-width: 900px) { 
    .text {
      &::before, &::after {
        display: none;
      }
    }
  }

  @media (max-width: 475px) { 
    .title {
      .text,
      .number {
        font-size: 34px;
      }
    }
  }