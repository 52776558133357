.header {
  // background-color: tomato;
  cursor: pointer;
  // background-color: tomato;

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__contacts {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      cursor: pointer;
    }

    p {
      cursor: pointer;
      font-weight: 400;
      font-size: 10px;
      line-height: 24px;
      text-transform: uppercase;

      span {
        color: rgba(255, 255, 255, 1);
        border: 0.8px solid #fff;
        border-radius: 4px;
        padding: 2px 4px;
        text-transform: uppercase;
        font-weight: 400;
      }
    }
  }

  &__contacts-box {
    position: relative;
    z-index: 10;
  }

  &__contacts-box-inner {
    cursor: pointer;
    display: flex;
    gap: 8px;
    color: rgba(255, 255, 255, 1);
    margin: 0;
    border-radius: 2px;
    // background-color: white;
    padding: 4px;

    p {
      text-transform: uppercase;

      font-weight: 600;
      color: #fff;
      letter-spacing: 2px;
      margin-right: 5px;
    }

    svg {
      fill: white;
    }

    &:hover {
      background-color: white;

      p {
        color: black;
      }

      svg {
        fill: black;
      }
    }
  }

  &__contacts-power {
    display: flex;
    gap: 7px;
    align-items: center;
    padding: 2px 7px;
    border: 0.8px solid #fff;
    border-radius: 4px;

    &:hover {
      background-color: #c2c7cc;
    }

    p {
      font-size: 10px;
      line-height: 13px;
      max-width: 144px;
      font-weight: 500;
      text-transform: initial;
      color: rgba(255, 255, 255, 1);
    }
  }

  &__list {
    // position: absolute;
    position: relative;
    // top: 30px;
    left: 0;
    width: 20vw;
    background-color: #fff;
    // border-radius: 2px;
    padding: 24px 0px;
    color: #292e33;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &::before {
      content: url("../../../public/img/menuline.svg");
      position: absolute;
      left: 35px;
      top: 15px;
    }

    &-first {
      p {
        font-size: 16px;
        line-height: 20px;
        text-transform: initial;
        padding: 10px 30px;
        transition: all 0.3s;

        span {
          color: #292e33;
          opacity: 0.5;
          border: none;
        }
      }

      img {
        position: absolute;
        right: 54px;
      }

      padding: 0px 24px;
      position: relative;

      &:hover {
        .header__list-second-outer {
          display: block;
          padding-right: 10px;
        }
        .header__list-first-title {
          background-color: #c2c7cc;
          // border-radius: 2px;

          &::before {
            content: url("../../../public/img/menuhover.svg");
            position: absolute;
            left: 35px;
            top: 10px;
          }
          &::after {
            content: url("../../../public/img/arrowmenuactive.svg");
            position: absolute;
            right: 35px;
            top: 12px;
          }
        }
      }
    }
    &-second-outer {
      display: none;
      position: absolute;
      top: 0;
      left: 20vw;
    }

    &-second-inner {
      min-width: 400px;
      background-color: #fff;
      padding: 16px;
      border-radius: 2px;

      li {
        a {
          display: flex;
          align-items: center;
          gap: 10px;
          opacity: 0.5;
          // transition: all 0.3s;
          border: 1px solid #fff;
          box-sizing: border-box;
          padding: 0 10px;

          &:hover {
            opacity: 1;
            border-color: #c2c7cc;
            border-radius: 2px;
          }
        }

        img {
          position: initial;
          object-fit: cover;
        }

        .sub-title,
        .sub-weight {
          padding: 0;
        }

        .sub-weight {
          padding: 16px 0 16px 16px;
          margin-left: auto;
          border-left: 1px solid #c2c7cc;
          width: 55px;
        }
        .sub-title {
          max-width: 200px;
        }
      }
    }
  }

  &__list2 {
    // position: absolute;
    overflow: hidden;
    position: relative;
    // top: 30px;
    left: 0;
    width: 20vw;
    background-color: #fff;
    // border-radius: 2px;
    padding: 24px 0px;
    color: #292e33;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &::before {
      content: url("../../../public/img/menuline.svg");
      position: absolute;
      left: 35px;
      top: 15px;
    }

    &-first2 {
      // padding-right: 50px;
      // background-color: tomato;
      p {
        font-size: 16px;
        line-height: 20px;
        text-transform: initial;
        padding: 10px 10px;
        transition: all 0.3s;
        // background-color: tomato;
        text-align: left;
        width: 100%;

        span {
          color: #292e33;
          opacity: 0.5;
          border: none;
        }
      }

      img {
        // position: absolute;
        // right: 154px;
        margin-left: 30px;
      }

      padding: 0px 24px;
      position: relative;

      &:hover {
        .header__list2-second2-outer2 {
          display: block;
          padding-right: 10px;
        }
        .header__list2-first2-title2 {
          background-color: #c2c7cc;
          // border-radius: 2px;
          // display: flex;

          &::before {
            content: url("../../../public/img/menuhover.svg");
            position: absolute;
            left: 35px;
            top: 10px;
          }
          // &::after {
          //   content: url("../../../public/img/arrowmenuactive.svg");
          //   position: absolute;
          //   right: 35px;
          //   top: 12px;
          // }
        }
      }
    }
    &-second-outer {
      display: none;
      position: absolute;
      top: 0;
      left: 20vw;
    }

    &-second-inner {
      min-width: 400px;
      background-color: #fff;
      padding: 16px;
      border-radius: 2px;

      li {
        a {
          display: flex;
          align-items: center;
          gap: 10px;
          opacity: 0.5;
          // transition: all 0.3s;
          border: 1px solid #fff;
          box-sizing: border-box;
          padding: 0 10px;

          &:hover {
            opacity: 1;
            border-color: #c2c7cc;
            border-radius: 2px;
          }
        }

        img {
          position: initial;
          object-fit: cover;
        }

        .sub-title,
        .sub-weight {
          padding: 0;
        }

        .sub-weight {
          padding: 16px 0 16px 16px;
          margin-left: auto;
          border-left: 1px solid #c2c7cc;
          width: 55px;
        }
        .sub-title {
          max-width: 200px;
        }
      }
    }
  }
}

.animated-text {
  max-width: 550px;
  position: relative;
  top: -50px;
  left: 90px;
}

.head-page {
  height: 100vh;

  h1 {
    max-width: 600px;
    font-size: 114px;
    line-height: 114px;
    color: #fff;
    margin-bottom: 0;
    text-align: right;
    font-weight: 800;
    margin-top: 40px;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    max-height: 1000px;
    object-fit: cover;
    z-index: -1;
  }
}

@media (max-width: 768px) {
  .head-page {
    h1 {
      font-size: 84px;
      line-height: 84px;
      max-width: 500px;
      margin: 0 auto;
      padding-top: 20px;
    }

    .animated-text {
      max-width: 450px;
      left: 15%;
    }

    video {
      object-position: -85vw 0;
      min-height: 120vh;
    }
  }
}

@media (max-width: 550px) {
  .head-page {
    h1 {
      font-size: 54px;
      line-height: 55px;
      padding-top: 10px;
      max-width: 254px;
      margin: initial;
      padding-top: 5px;
    }
    .animated-text {
      max-width: 285px;
      top: -40px;
      left: 0;
    }
    video {
      object-position: -165vw 0px;
      min-height: 125vh;
    }
  }

  .header__contacts-box {
    // margin-right: 0;
    display: none;
  }

  .about__content-block {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.header__wrapper {
  // border: 1px solid tomato;

  position: relative;
  top: 35px;
  height: auto;

  .header__title {
    background-color: white;
    color: #292e33;
    display: flex;
    padding: 10px 15px;
    text-align: center;
    gap: 10px;
    align-items: center;

    p {
      margin-right: 10px;
    }
  }

  @media (max-width: 767px) {
    position: static;
    top: 0;
  }
}

.header__wrapperText2 {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.header__wrapperTextLink2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__icon {
  margin-left: auto;
}