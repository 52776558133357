@import "./../../../styles/variables.scss";

.energy {
    padding: 50px 0 245px 0;

    &__inner {
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      gap: 5px;
      // flex-wrap: wrap;
      max-width: 1120px;

      a {
        max-width: 352px;
        min-width: 215px;
        width: 100%;
        padding: 24px;
        border-radius: 4px;
        background-color: #DAE0E5;
        position: relative;

        &:hover {
          background-color: #d0d6db;
        }

        img {
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
        }

        span {
          display: block;
          text-align: center;
          max-width: 165px;
          margin: 0 auto;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: $titleTextCol;
        }
      }
    }

    &-supply {
      padding: 65px 0 200px 0;
    }
  }

  .energy-button {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background-color: #DAE0E5;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 28px 16px;
    max-width: 448px;
    width: 100%;
    border-radius: 4px;

    &:hover {
      background-color: #d0d6db;
    }

  }

  .energy-button-inner {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
  }

  .energy-supply-block {
    margin-bottom: 105px;

    &--btn {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px;

      a {
        color: #FFFFFF;
        background-color: #EDB012;
        border-radius: 4px;
        padding: 21px 60px;
        font-weight: 700;

        &:hover {
          background-color: #fab913;
        }
      }

      p {
        color: #EDB012;
        font-weight: 700;
        max-width: 258px;
      }
    }

    ol {
      li {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;

        &:hover {
          font-weight: 500;
        }

        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          margin-right: 10px;
        }

        a {
          display: flex;
          align-items: baseline;
          max-width: 665px;
        }

        img {
          margin-left: 8px;
        }
      }
    }

    ul {

      li {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        margin-bottom: 20px;

        span {
          font-weight: 700;
        }
      }
    }
  }


  @media (max-width: 685px) {
    .energy { 
      &__inner { 
        flex-wrap: wrap;
        gap: 40px;

        a { 
          max-width: 100%;
        }
      }
    }
  }