.cardWrapper {
  width: 230px;
  margin-bottom: 150px;
  position: relative;

  p {
    font-family: Open Sans;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(41, 46, 51, 1);
  }

  .wrapper_borderImg {
    max-width: 200px;
    margin-bottom: 11px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      background: rgb(161, 164, 167, 0.5);
      border-radius: 50%;
      width: 16px;
      height: 16px;

      left: 0;
      bottom: 0;
    }

    &::after {
      content: "";
      position: absolute;
      display: block;
      background: rgb(161, 164, 167, 0.5);
      border-radius: 50%;
      width: 8px;
      height: 8px;

      right: 0;
      top: 0;
    }

    .borderImg {
      max-width: 200px;
      border-radius: 50%;
      overflow: hidden;

      position: relative;

      img {
        width: 200px;
        display: block;

        @media (max-width: 1360px) {
          width: 128px;
        }
      }

      @media (max-width: 1360px) {
        width: 128px;
      }
    }

    @media (max-width: 1360px) {
      width: 128px;
    }
  }

  .firstName {
    font-weight: 400;
    margin-bottom: 5px;
  }

  .lastName {
    font-weight: 700;
    margin-bottom: 11px;
  }

  .contact {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 600;
      color: rgba(41, 46, 51, 1);

      @media (max-width: 1360px) {
        font-size: 14px;
      }
    }
    button {
      background-color: transparent;
      border: none;
      margin-left: 10px;
      transition: color 500ms linear;
      cursor: pointer;
      padding: 0;
      width: 16px;
      height: 16px;

      .iconCopy {
        fill: rgba(69, 73, 77, 1);
        width: 16px;

        &:hover {
          fill: white;
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    background: rgba(194, 199, 204, 1);
    width: 16px;
    height: 2px;

    left: 0;
    bottom: -37px;
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
}
