.application-form {
    padding-top: 10px;

    &__title {
        h3 {
            color: #fff;
            font-family: Rubik Mono One,sans-serif;
            font-size: 60px;
            font-weight: 400;
            margin-bottom: 50px;
            text-transform: uppercase;
        }
    }

    &__field {
        input {
            height: 40px;
            width: 256px;
            padding-left: 16px;
            font-size: 16px;
            font-weight: "Open Sans", sans-serif;
            background-color: transparent;
            outline: none;
            border: 1px solid rgba(255,255,255, 0.5);
            border-radius: 2px;
            margin-bottom: 8px;
            color: #fff;
            &::placeholder {
                color: #fff;
            }
        }
    }


    &__consent {
        max-width: 400px;
        margin-bottom: 16px;
        label {
            cursor: pointer;
        }
    }

    &__send {
        button {
            width: 244px;
            height: 40px;
            background-color: rgba(255,255,255, 0.3);
            border: none;
            border-radius: 2px;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            border: none;
            transition: all 0.3s;

            &:hover {
                background-color: rgba(255,255,255, 0.2);
            }

            &:disabled {
                background-color: rgba(255,255,255, 0.1);
            }
        }
    }

    form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
        max-width: 750px;
    }

    .form-check-input {
        margin-right: 10px;
    }
}

@media (max-width: 1050px) { 
    .application-form__consent {
        max-width: 325px;
    }
}

@media (max-width: 660px) {  
    .application-form__title h3 {
        font-size: 40px;
        margin-bottom: 25px;
    }
    .application-form__field input {
        width: 100%;
        max-width: 325px;
    }
}