.NewsWrapper {
  padding-top: 30px;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 15px;
    }
  }
  @media (max-width: 560px) {
    h2 {
      font-size: 18px;
    }
    h3 {
      font-size: 16px;
    }
  }
  @media (max-width: 460px) {
    h2 {
      font-size: 16px;
    }
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 13px;
    }
  }
  @media (max-width: 410px) {
    h2 {
      font-size: 14px;
      //width: 90%;
    }
    h3 {
      font-size: 13px;
    }
    p {
      font-size: 12px;
    }
  }
}

.pages-head {
  .wrapper {
    img {
      z-index: -1;
    }
  }
}

.news-page {
  //   border: 1px solid tomato;
  //   display: flex;
  gap: 20px;
  margin-bottom: 60px;
  padding-top: 100px;
  @media (max-width: 460px) {
    padding-top: 5px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
  }
  img {
    display: block;
    // margin-top: 40px;
    height: 100%;
    width: 100%;
    max-width: 550px;
    max-height: 550px;
    text-align: center;
    align-items: center;
    margin: auto;

    @media (max-width: 1051px) {
      text-align: center;
    }
  }

  .news-page__inner {
    // border: 1px solid rgb(8, 0, 255);
  }
}

@media (max-width: 1051px) {
  .news-page {
    flex-wrap: wrap;
  }
}

.wrapper {
  display: flex;
  gap: 30px;

  @media (max-width: 1051px) {
    flex-wrap: wrap;
  }
}

//////////////////////////////////////////////////////
.container {
  // width: 544px;
  margin-left: 120px;
  margin-right: 120px;
  width: 100%;
  /*==========================================================================*/
  @media (max-width: 1051px) {
    margin-left: 5px;
    margin-right: 5px;
  }
  //@media (max-width: 768px){
  //  margin-left: 5px;
  //  margin-right: 5px;
  //}
  @media (max-width: 560px) {
  }
  @media (max-width: 460px) {
    margin-left: 1px;
  }
  @media (max-width: 410px) {
    margin-left: -20px;
  }
  /*============================================================================*/
  &__a1 {
    width: 544px;
    /*==========================================================*/
    @media (max-width: 560px) {
      max-width: 70%;
      display: flex;
      flex-direction: column;
    }
    /*==============================================================*/
    &__span1 {
      color: grey;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  &__a2 {
    margin-top: 100px;
    display: flex;
    // flex-direction: row;
    // flex-grow: 1;
    width: 100%;
    flex-wrap: wrap;
    border-top: 1px solid grey;

    @media (max-width: 560px) {
      margin-top: 10px;
    }

    &__b1 {
      // display: block;
      flex-grow: 1;

      min-width: 544px;
      width: 60%;
      //@media(max-width: 600px){
      //    min-width:400px;
      //}
    }
    &__b2 {
      // margin-left: 10%;
      display: flex;
      width: 40%;
      @media (max-width: 560px) {
      }
    }
  }

  &__a3 {
    margin-top: 50px;

    ////////////////////////////////////////////////////////////////////////
    ///
    display: flex;
    flex-direction: column;
    @media (max-width: 460px) {
      margin-top: 10px;
    }
    &__b1 {
      flex-wrap: wrap;
      &__c1 {
        display: flex;
        width: 60%;
        min-width: 544px;
        flex-wrap: wrap;

        &__d1 {
          width: 30%;
          @media (max-width: 560px) {
            width: 100%;
          }
        }
        &__d2 {
          width: 70%;
        }
      }
    }
    ///////////////////////////////////////////////////////////////////
    ///
    &__b2 {
      // margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      &__c1 {
        display: flex;
        width: 60%;
        min-width: 544px;
        flex-wrap: wrap;
        &__d1 {
          width: 30%;
          padding-top: 0px;
          @media (max-width: 560px) {
            width: 100%;
          }
        }
        &__d2 {
          width: 70%;
        }
      }

      &__c2 {
        display: flex;
        width: 40%;
        min-width: 400px;
        &__d1 {
          width: 100%;
        }
      }
    }
  }
}
