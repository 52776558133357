.contacts_social__wrapper {
  flex: 0.5;
  // border: 1px solid tomato;


  .messageIcon {
    margin-bottom: 8px;
    fill: rgba(194, 199, 204, 1);
  }
  p {
    color: #c2c7cc;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    margin-bottom: 18px;
  }

  .social__list {
    display: flex;

    li {
      height: 20px;
    }
    
    .icons {
      fill: rgba(194, 199, 204, 1);
      margin-right: 28px;
      width: 20px;
      height: 20px;
    }
    
    
    
    
    @media (max-width: 1360px) {
    }
    
    @media (max-width: 768px) {
      
      margin: 0;
    }
  }





}
