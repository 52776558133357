.power {
    margin-top: 100px;
  
    .beetroot {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 100px;
  
      &__descr {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 30px;
        counter-reset: countP;
      }
  
      &__descr-item {
        max-width: 770px;
        padding: 10px 16px;
        border: 1px solid #FFFFFF;
        display: flex;
        border-radius: 2px;
        gap: 80px;
  
        span {
          font-weight: 700;
          color: #9C7BA6;
        }
  
        img {
          height: 100%;
        }
  
  
        p {
          position: relative;
          margin-bottom: 10px;
  
          &::before{
            counter-increment: countP;
            position: absolute;
            content: "0"counter(countP);
            left: -40px;
            top: 0;
            color: #C2C7CC;
            font-size: 14px;
          }
        }
      }
    }
  
    .azot {
      margin-bottom: 100px;
  
      .beetroot__descr-item {
        flex-wrap: wrap;
        gap: 20px;
      }
  
      .beetroot__descr-item.big {
        max-width: 1140px;
      }
  
      &__inner {
  
        &-all {
          width: 100%;
        }
        &-half{
          max-width: 600px;
        }
      }
      
    }
  
    .table-link {
      padding: 10px 5px 0 0;
      color: #676D73;
      display: inline-block;
    }
  
    .organization {
  
      &__subtitle {
        color:#292E33;
        font-weight: 600;
        line-height: 32px;
        max-width: 550px; 
        margin-bottom: 10px;
      }

      &__table-inner {
        max-width: 100%;
      }

      &__table {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 35px;
        margin: 35px 0;
      }
    }
  
    .text-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      text-align: center;
    }
  
    .power-elements {
      margin: 50px 0 100px 0;
      
      &__descr {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;
      }
  
      &__descr-item {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  
    .elements-inner {
      display: flex;
      max-width: 1200px;
      flex-wrap: wrap;
      gap: 35px;
      margin-bottom: 50px;
    }
    
    .microelements {
      margin-bottom: 200px;
    }
  }


  @media (max-width: 1200px) {
    .power .organization {
      &__table-inner {
        overflow-x: auto;
      }
      &__table {
        gap: 10px;
        justify-content: space-between;
        min-width: 1030px;
      }
    }

    .power .elements-inner {
      min-width: 1145px;
    }

    .power .power-elements {
      padding: 50px 0 100px 0;
      overflow-x: auto;
    }

  }

  @media (max-width: 600px) {
    .power .beetroot__descr-item {
      flex-wrap: wrap;
      gap: 10px;
    }
  }