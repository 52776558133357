.modal {
    position: absolute;
    left: 35%;
    max-width: 750px;
    width: 100%;
    background-color: #292E33;
    color: #FFF;
    z-index: 2;
    padding: 32px;

    h3 {
        font-size: 40px;
        font-weight: 700;
        line-height: 40px;
        display: inline-flex;
        gap: 50px;
        margin-bottom: 20px;

        svg {
            cursor: pointer;
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 15px;
    }
}

.data {
    display: flex;
    flex-direction: column;
    margin-top: 56px;

    &__element {
        display: flex;
        gap: 16px;
        align-items: center;

        p {
            width: 200px;
        }

        span {
            font-size: 32px;
            font-weight: 700;
            line-height: 24px;
        }
    }
}