@media (max-width: 600px) {
}
@media (max-width: 440px) {
}

.energyprodContainer {
  --color-dark-black: #292e33;
  --background-color-grey: #dae0e5;
  --border-color-grey: #c2c7cc;
  --color-div-svg: #33363f;
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  margin-right: 120px;
  margin-top: 30px;
  box-sizing: border-box;
  @media (max-width: 470px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  &__innerContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // border-top-style: solid;
    // border-top-width: 1px;
    // border-top-color: var(--border-color-grey);
    // margin-top: 20px;
    margin-top: 40px;
    margin-bottom: 60px;
    /////////////////////////////
    padding-top: -1px;

    &__section1 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 20px;
      /////////////////////////////////////////////////

      h1 {
        width: 46px;
        font-weight: 700;
        font-size: 46px;
        line-height: 16px;
        display: block;
        // margin-top: 40px;
        margin-top: 20px;
        ///////////////////////////////////////////////////
      }
      p {
        width: 402px;
        height: 57px;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
      }
    }

    &__section2 {
      width: 448px;
      //   width: 40%;
      height: 80px;
      background-color: var(--background-color-grey);
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 4px;

      &:hover {
        background-color: #d0d6db;
      }
      @media (max-width: 600px) {
        width: 300px;
      }
      p {
        display: flex;
        align-items: center;
        margin-left: 16px;
        width: 100%;
        height: 100%;

        a {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: var(--color-dark-black);
        }
      }
    }
    &__section3 {
      p {
        font-weight: 400;
        font-size: 16px;
        width: 70%;
        margin-bottom: 20px;
        //////////////////////////////////////
        display: flex;
        gap: 10px;
        span {
          display: block;
          font-weight: 700;
        }
        ///////////////////////////////////////
        @media (max-width: 600px) {
          width: 95%;
        }
        a {
          text-decoration: none;
          color: var(--color-dark-black);
          &:hover {
            font-weight: 500;
          }
        }
      }
    }
  }
}
.p4 {
  margin-top: 15px;
}
.div__h1 {
  // border-top-style: solid;
  // border-top-width: 5px;
  // border-top-color: var(--border-color-grey);
}

// .div__p {
//   border-top-style: solid;
//   border-top-width: 1px;
//   border-top-color: var(--border-color-grey);
// }

.div__svg {
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--color-div-svg);
}

// .energyButton {
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 24px;
//   background-color: #dae0e5;
//   display: flex;
//   justify-content: space-between;
//   align-items: baseline;
//   padding: 28px 16px;
//   max-width: 448px;
//   width: 100%;
//   height: 100%;
//   border-radius: 4px;
//   ///////////
//   padding-left: 16px;
//   a {
//     display: block;
//     width: 100%;
//   }
//   &:hover {
//     background-color: #d0d6db;
//   }
// }
