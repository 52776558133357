.period {
    margin-bottom: 4px;

    &__number {
        display: inline-flex;
        align-items: center;
        position: relative;
        bottom: -10px;
        left: -2px;
    }
}

.openDates {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-bottom: 54px;



    & img {
        width: 100%;
        object-fit: cover;
        -webkit-mask-image: linear-gradient(to left, transparent, black 10%);
        mask-image: linear-gradient(to left, transparent, black 20%);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
    }
}

.description {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    background-color: #005BAA;
    color: #fff;
    border-radius: 4px;
    padding: 32px;
    max-width: 544px;
    margin-bottom: 60px;
}

.division {

    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    & .division-card {
        min-width: calc(50% - 16px);
        max-width: 544px;
        display: flex;
        flex-direction: column;
        margin-bottom: 116px;

        & .title {
            min-height: 102px;
            display: flex;
            gap: 10px;
            font-family: 'Open Sans', sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0.05em;
            color: #292E33;
            margin-bottom: 12px;
            align-items: flex-start;

            & .title-nums {
                height: max-content;
                display: flex;
                position: relative;
                left: 16px;

                &::before {
                    content: '';
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    left: -16px;
                    background-color: #292E33;
                    border-radius: 50px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                & .title-num-2 {
                    margin-left: 4px;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #C2C7CC;
                }
            }

            & .title-text {
                text-transform: uppercase;
                margin-left: 16px;
            }
        }

        & .content-1 {
            min-height: 104px;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            margin-bottom: 16px;
        }

        & .content-2 {
            & .content-title {
                min-height: 47px;
                margin-bottom: 19px;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                color: #292E33;

                & span {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                }
            }

            & .content-range {
                display: flex;
                gap: 1px;
                margin-bottom: 46px;

                & .content-range-part {
                    width: calc((100% / 9) - 1px);
                    background-color: #C2C7CC;
                    border-radius: 4px;
                    height: 8px;
                }

                & .content-range-part-active {
                    background-color: #005BAA;
                    width: calc((100% / 9) - 1px);
                    border-radius: 4px;
                    height: 8px;
                }

                & .content-range-part-75 {
                    background: linear-gradient(to right, #005BAA 75%, #C2C7CC 25%);
                    width: calc((100% / 9) - 1px);
                    border-radius: 4px;
                    height: 8px;
                }
            }

            & .content-footer {
                min-height: 52px;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                color: #292E33;

            }
        }
    }
}