.footerWrapper {
  background: var(--unnamed, #222426);

  padding-bottom: 74px;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}
