.title {
  position: relative;
  border: 1px solid #fff;
  padding: 16px;
  width: 50%;
  // margin: 262px 0 122px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #292e33;
  margin-bottom: 100px;

  @media (max-width: 1024px ) {
    width: 80%;
  }

  &__text-1 {
    position: relative;
    margin-bottom: 24px;

    &::before {
      content: "01";
      position: absolute;
      top: 0;
      left: -40px;
      font-size: 14px;
      font-weight: 400;
      color: #c2c7cc;
    }
  }

  &__text-2 {
    position: relative;
    margin-bottom: 24px;

    &::before {
      content: "02";
      position: absolute;
      top: 0;
      left: -40px;
      font-size: 14px;
      font-weight: 400;
      color: #c2c7cc;
    }
  }

  &__text-3 {
    position: relative;
    margin-bottom: 24px;

    &::before {
      content: "03";
      position: absolute;
      top: 0;
      left: -40px;
      font-size: 14px;
      font-weight: 400;
      color: #c2c7cc;
    }
  }

  &__text-4 {
    position: relative;
    margin-bottom: 24px;

    &::before {
      content: "04";
      position: absolute;
      top: 0;
      left: -40px;
      font-size: 14px;
      font-weight: 400;
      color: #c2c7cc;
    }
  }

  &__text-5 {
    position: relative;
    margin-bottom: 24px;

    &::before {
      content: "05";
      position: absolute;
      top: 0;
      left: -40px;
      font-size: 14px;
      font-weight: 400;
      color: #c2c7cc;
    }
  }

  &__link-t {
    font-size: 14px;
    color: #676d73;
    font-weight: 400;
    line-height: 24px;
    display: block;
    margin-bottom: 24px;

    &::after {
      content: url(./../../media/images/icons/table-arr.svg);
      background-size: contain;
      margin-left: 10px;
    }
  }

  &__title {
    font-weight: 700;
    margin-bottom: 16px;
  }
}

.list {
  list-style: none;
  padding: 0;

  &__item {
    position: relative;
    margin-bottom: 25px;
    margin-left: 25px;

    &::before {
      position: absolute;
      content: url(./../../media/images/icons/list.svg);
      width: 10px;
      height: 10px;
      left: -25px;
    }
  }
}

.deadlines {
  margin-bottom: 138px;

  &__subtitle {
    width: 544px;
    line-height: 32px;
    font-size: 24px;
    font-weight: 600;
    color: #292e33;
    margin-bottom: 5px;
  }

  &__text {
    width: 50%;
    line-height: 24px;
    font-size: 16px;
    color: #292e33;
  }
}

.table {
  margin-bottom: 117px;

  &__title {
    font-size: 14px;
    color: #676d73;
    margin-bottom: 35px;

    &::after {
      content: url(./../../media/images/icons/table-arr-l.svg);
      background-size: contain;
      margin-left: 10px;
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1360px) {
      flex-direction: column;
      
    }
  }

  &__col {
    width: 50%;

    &--title {
      font-weight: 700;
      font-size: 32px;
      color: #292e33;
      margin-bottom: 6px;
    }

    &--subtitle {
      font-size: 16px;
      font-weight: 400;
      color: #676d73;
      margin-bottom: 57px;
    }
  }

  &__row {
    &--wrapper1 {
      display: flex;
      flex-direction: column;
      gap: 112px;
    }

    &--wrapper2 {
      display: flex;
      flex-direction: column;
      gap: 75px;
    }

    &--flex {
      display: flex;
      justify-content: space-between;
      gap: 33px;
    }
  }
}

.desc {
  &__temp {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &--numb {
      font-size: 48px;
      font-weight: 700;
      color: #845713;
      margin-right: 20px;
    }

    &--text {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
    }
  }

  &__temp-2 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &--numb {
      font-size: 48px;
      font-weight: 700;
      color: #5ca6e7;
      margin-right: 20px;
    }
  }

  &__deadline {
    &--text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #292e33;
      margin-bottom: 5px;
    }

    &--pag {
      display: flex;
    }
  }

  &__deadline-2 {
    &--text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #292e33;
      margin-bottom: 5px;
      width: 258px;
    }
  }
}

.pag {
  &__full {
    width: 12px;
    height: 12px;
    border: 2px solid #62c960;
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 0px 1px 1px rgba(98, 201, 96, 0.75);
    -moz-box-shadow: inset 0px 0px 1px 1px rgba(98, 201, 96, 0.75);
    box-shadow: inset 0px 0px 1px 1px rgba(98, 201, 96, 0.75);
    margin-right: 6px;
  }

  &__empty {
    width: 12px;
    height: 12px;
    border: 2px solid rgba(176, 228, 175, 1);
    border-radius: 50%;
    -webkit-box-shadow: inset 0px 0px 1px 1px rgba(176, 228, 175, 1);
    -moz-box-shadow: inset 0px 0px 1px 1px rgba(176, 228, 175, 1);
    box-shadow: inset 0px 0px 1px 1px rgba(176, 228, 175, 1);

    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}

.scheme {
  &__text {
    width: 49%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #292e33;
    margin-bottom: 100px;
  }

  &__subtitle {
    width: 390px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #292e33;
  }
}

.control {
  margin-bottom: 100px;
  &__title-1 {
    color: #292e33;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    position: relative;
    height: 100px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 28px;

    &::before {
      content: "";
      height: 2px;
      width: 24px;
      background-color: #292e33;
      position: absolute;
      top: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    &--left {
      width: 50%;
    }
    &--right {
      width: 50%;
    }

    @media (max-width: 1024px) {
      flex-direction: column;


      &--left {
        width: 100%;
      }

      &--right {
        width: 100%;
      }
    }
  }

  &__item-1 {
    padding: 12px 8px;
    width: 100%;
    background-color: #eea83e;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 2px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__item-1 span {
    color: #fff;
    opacity: 40%;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    margin-right: 10px;
  }
}

.subtitle {
  width: 50%;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #292e33;
  margin-bottom: 5px;
}

.lt {
  font-size: 14px;
  line-height: 24px;
  color: #676d73;
  margin-bottom: 35px;

  &::after {
    margin-left: 5px;
    content: url(../../media/images/icons/table-arr-l.svg);
  }
}

.textFW700 {
  font-weight: 700;
}