.product {
    margin-top: -130px;

    &__inner {
        display: flex;
        gap: 20px;
        justify-content: space-between;

        h1 {
            font-size: 88px !important;
            line-height: 88px !important;
            margin-bottom: 15px !important;
        }
        h4 {
            justify-content: flex-start !important;
            // display: inline !important;
            font-weight: 700 !important;
            font-size: 24px !important;
            
            span {
                background-image: none !important;
                min-width: auto !important;
            }
        }
        img {
            z-index: 0 !important;
            position: relative !important;
            object-fit: contain !important;
            object-position: right !important;
            max-height: 590px;
            max-width: 850px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        color: #FFF;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400px;

        &-inner {
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            max-width: 630px;
        }

        &-descr {
            display: flex;
            gap: 40px;
            p {
                display: flex;
                align-items: center;
                gap: 12px;
            }
        }
        &-nutrit, 
        &-carb,
        &-order {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }

    .breadcrump-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
        color: #FFF;
        margin-bottom: 40px;
        margin-top: 40px;
    }
    .measur {
        padding: 8px 10px;
        border: 1px solid rgba($color: #fff, $alpha: 0.2);
        border-radius: 2px;

        span {
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
        }
    }

    .content {
        p {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

@media (max-width: 1250px) {
    .product__inner{
        img {
            max-width: 375px;
            object-position: 60px !important;
        }
    }
  }

@media (max-width: 1050px) {
    .product__inner{
        flex-wrap: wrap;
        flex-direction: column-reverse;
        img {
            max-width: 100%;
            object-position: center !important;
        }
        h1 {
            text-align: left !important;
        }
    }
}

@media (max-width: 685px) { 
    .product__inner{
        h1 {
            font-size: 61px !important;
            line-height: 76px !important;
        }
    }
}

@media (max-width: 485px) { 
    .product__inner{
        h1 {
            font-size: 45px !important;
            line-height: 58px !important;
        }
    }
}