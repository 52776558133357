.contactsWrapper {
  display: flex;
  justify-content: space-between;
  // background-color: green;
  padding-bottom: 38px;
  // flex-wrap: wrap;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    // display: flex;
    flex-direction: column;
    // background-color: tomato;
  }
}
