$titleTextCol: #292e33;
$titleNumCol: #c2c7cc;

$vacanciesContentColor: #292e33;

$vacTitleColor: #292e33;
$vacContentColor: #292e33;

$acordTextColor: #292e33;
$acordTextActiveColor: #fff;
$acordBorderColor: #c2c7cc;
$acordNumberColor: #676d73;
$acordBgActive: #292e33;

$studyTitleColor: #292e33;
$studyBtnBorderColor: #c2c7cc;
$studyBtnTextColor: #292e33;
$studyCardBorderColor: #c2c7cc;
$studyCardColor: #292e33;
$studyCardLineColor: #292e33;
$studyCardTextColor: #292e33;