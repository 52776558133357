.crop-care {
    margin-top: 100px;
  
    &__descr {
        max-width: 576px;
        padding: 16px 16px 0 16px;
        border: 1px solid #FFFFFF;
        border-radius: 2px;
        margin-bottom: 100px;

        p {
            margin-bottom: 16px;
        }
        span.gray {
            color: #C2C7CC;
        }
        span.black {
            color: #292E33;
            font-weight: 700;
        }
    }

    &__scheme{
        counter-reset: countBlock;
        overflow-x: auto;

        h3 {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            max-width: 550px;
        }

        p {
            max-width: 90px;
        }

        a {
            font-weight: 400;
            line-height: 24px;
            color: #676D73;
            margin: 10px 0 30px 0;
            display: inline-block;
        }
    }
}

.scheme-block {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    margin-left: 85px;
    min-width: 650px;

    &::before {
        counter-increment: countBlock;
        position: absolute;
        content: "0"counter(countBlock);
        left: -85px;
        top: 50%;
        transform: translateY(-50%);
        color: #C2C7CC;
        font-size: 48px;
        line-height: 32px;
        -webkit-text-stroke-color: #C2C7CC;
        -webkit-text-fill-color: #F2F2F3;
        -webkit-text-stroke-width: 1px;
    }
}