.socialActivityHead {

  .wrapper {
    max-width: 500px;
    margin-left: auto;

    h2 {
      font-family: "Rubik Mono One", sans-serif;
      font-size: 61px;
      font-weight: 400;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 15px;
      color: rgba(255, 255, 255, 1);

      @media (max-width: 768px) {

        // margin-right: 0;
        font-size: 30px;
       }
    }

    p {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(255, 255, 255, 1);


    }
  }
}
