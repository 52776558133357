.wrapperCard {
  position: relative;
  // border: 1px solid tomato;
  width: 350px;
  box-sizing: border-box;
  display: flex;

  // background: tomato;
  flex-direction: column;


  h2 {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 91, 170, 1);
    margin-bottom: 10px;

    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 10%;
      top: -10px;
      left: 0;
      border-top: 3px solid rgba(0, 91, 170, 1);
      
    }
  }

  h3 {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(41, 46, 51, 1);
    margin-bottom: 8px;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;


      p {
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(103, 109, 115, 1);
      }

      .icon {
        color: rgba(194, 199, 204, 1);


      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }




  
@media (max-width: 768px) {
  width: 100%;
}

}
