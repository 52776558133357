.table {
  margin-bottom: 100px;
  display: flex;
  overflow: scroll;
}

.col-1__row-1,
.col-1__row-2,
.col-1__row-3,
.col-1__row-4,
.col-1__row-5,
.col-1__row-6,
.col-1__row-7,
.col-1__row-8 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 48px;
  width: 160px;

  &--text {
    font-size: 16px;
    color: #292e33;
    font-weight: 600;
  }

  &--pag {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--pag-1,
  &--pag-2,
  &--pag-3 {
    width: 10px;
    height: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &--pag-1 {
    background-image: url(./../../../media/images/icons/pag-1.svg);
  }

  &--pag-2 {
    background-image: url(./../../../media/images/icons/pag-2.svg);
  }

  &--pag-3 {
    background-image: url(./../../../media/images/icons/pag-3.svg);
  }

  &--pag-4 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: #62c960;
    border-radius: 50%;

    &::before {
      content: url(./../../../media/images/icons/pag-4.svg);
      width: 10px;
      height: 10px;
      position: absolute;
      top: -8px;
    }
  }

  &--pag-5 {
    width: 8px;
    height: 8px;
    background-color: #62c960;
    border-radius: 50%;
  }

  &--pag-6 {
    position: relative;
    width: 8px;
    height: 8px;
    background-color: #62c960;
    border-radius: 50%;

    &::before {
      content: url(./../../../media/images/icons/pag-6.svg);
      width: 10px;
      height: 10px;
      position: absolute;
      top: -8px;
    }
  }
}

.col-2__row-1,
.col-2__row-2,
.col-2__row-3,
.col-2__row-4,
.col-2__row-5,
.col-2__row-6,
.col-2__row-7,
.col-2__row-8,
.col-3__row-1,
.col-3__row-2,
.col-3__row-3,
.col-3__row-4,
.col-3__row-5,
.col-3__row-6,
.col-3__row-7,
.col-3__row-8,
.col-4__row-1,
.col-4__row-2,
.col-4__row-3,
.col-4__row-4,
.col-4__row-5,
.col-4__row-6,
.col-4__row-7,
.col-4__row-8,
.col-5__row-1,
.col-5__row-2,
.col-5__row-3,
.col-5__row-4,
.col-5__row-5,
.col-5__row-6,
.col-5__row-7,
.col-5__row-8,
.col-6__row-1,
.col-6__row-2,
.col-6__row-3,
.col-6__row-4,
.col-6__row-5,
.col-6__row-6,
.col-6__row-7,
.col-6__row-8,
.col-7__row-1,
.col-7__row-2,
.col-7__row-3,
.col-7__row-4,
.col-7__row-5,
.col-7__row-6,
.col-7__row-7,
.col-7__row-8 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 48px;
  width: 155px;

  &--text {
    font-size: 16px;
    color: #292e33;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7 {
  &__range {
    height: 69px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    &--text {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #292e33;
    }
  }
}

.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7 {
  &__dumbbell {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left,
    &--right {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #62c960;
    }

    &--line {
      height: 2px;
      background-color: #c2c7cc;
    }
  }
}

.col-1 {
  &__subtitle {
    width: 160px;
    height: 69px;
    color: #292e33;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.col-2 {
  &__dumbbell {
    width: 36px;

    &--line {
      width: 16px;
    }
  }
}

.col-3 {
  &__dumbbell {
    width: 52px;

    &--line {
      width: 32px;
    }
  }
}

.col-4 {
  &__dumbbell {
    width: 72px;

    &--line {
      width: 52px;
    }
  }
}

.col-5 {
  &__dumbbell {
    width: 103px;

    &--line {
      width: 83px;
    }
  }
}

.col-6 {
  &__dumbbell {
    width: 121px;

    &--line {
      width: 101px;
    }
  }
}

.col-7 {
position: relative;

  &__title {
    position: absolute;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    width: 352px;
    height: 32px;
    text-align: right;
    left: -198px;
  }
  &__dumbbell {
    width: 144px;

    &--line {
      width: 124px;
    }
  }
}
