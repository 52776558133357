
.element {
    padding: 20px;
    min-width: 200px;
    border: 1px solid #FFFFFF;
    position: relative;
    text-align: center;
}

.image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40%;
}

.text {
    color: #292E33;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;

    span {
        color: #C2C7CC;
    }
}