@import "./../../styles/variables.scss";

.energy-title {
    margin-bottom: 40px;
    
    img {
        min-width: 100%;
        margin-bottom: 16px;
    }

    h3 {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        max-width: 515px;
        color: $titleTextCol;

        span {
            font-size: 46px;
            font-weight: 700;
            line-height: 16px;
        }
    }
}