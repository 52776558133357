.report {
    padding: 65px 0;
    // ==========================================
    .filterWrapper {
    ul {
        display: flex;
        gap: 16px;
        margin-bottom: 64px;
        flex-wrap: wrap;

        li {
        button {
            width: 120px;
            min-height: 40px;
            background-color: transparent;
            border: 1px solid rgba(194, 199, 204, 1);
            border-radius: 2px;
            color: #45494d;
            cursor: pointer;
            user-select: none;
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
        }

        .active__button {
            background-color: rgba(194, 199, 204, 1);
            color: #fff;
        }
        }
    }
    }
}