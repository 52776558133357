* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  background-color: #f2f2f3;
}

#root {
  overflow-x: hidden;
}

@font-face {
  font-family: "RubikOne";
  src: local("RubikOne-Regular"),
    url("../fonts/RubikOne-Regular.woff2") format("woff2");
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

@import "mixins.scss";
@import "acord.scss";

.container {
  max-width: 1920px;
  padding: 0 120px;
  margin: 0 auto;

  // @include onPhone {
  //   margin: 0 auto;
  //   max-width: 320px;
  //   position: relative;
  //   padding: 0 28px;
  // }

  // @include onTV {
  //   max-width: 1920px;
  //   padding: 0 160px;
  // }
}

.loader.sm {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #5f5f6a; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

.loader.big {
  width: 120px;
  height: 120px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.main {
  &__video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__logo {
    margin-right: auto;
    display: flex;
    align-items: flex-end;
    gap: 5px;
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    gap: 32px;

    .room {
      display: none;
    }
  }

  &__contact {
    font-size: 16px;
    margin: 29px 0 0 0;
    display: flex;
    gap: 15px;

    a {
      user-select: none;
    }

    &--company {
      height: 22px;
      position: relative;
      color: #fff;

      .contact-btn {
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        letter-spacing: 2px;

        &::after {
          content: "";
          height: 2px;
          width: 0;
          background-color: #fff;
          transition: all 0.3s;
          position: absolute;
          left: 0;
          bottom: -5px;
        }
      }

      &:hover {
        .contact-btn {
          color: #fff;
          &::after {
            width: 100%;
          }
        }
        .main__contact-drop {
          opacity: 1;
          clip: auto;
        }
      }
    }

    &--contact {
      color: #fff;
      text-transform: uppercase;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      letter-spacing: 2px;
    }
  }

  &__contact-drop {
    position: absolute;
    top: 10px;
    right: 0;
    padding-top: 30px;
    cursor: pointer;
    opacity: 0;
    clip: rect(0, 0, 0, 0);
  }

  &__contact-drop-inner {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 2px;
    color: #292e33;
    padding: 16px 24px 24px 24px;
    max-width: 200px;

    a {
      padding: 8px;
      border-radius: 2px;

      &:hover {
        color: #fff;
        background-color: #292e33;
      }
    }
  }

  &__lang {
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    position: relative;

    &:hover {
      .lang-list {
        opacity: 1;
        clip: auto;
      }
    }

    .lang-list {
      position: absolute;
      opacity: 0;
      clip: rect(0, 0, 0, 0);
      padding-top: 30px;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s;

      ul {
        padding: 5px;
        border-radius: 2px;
        background-color: #fff;
        color: #292e33;

        li {
          padding: 5px 10px;
          color: #292e33;
          border-radius: 2px;
          &:hover {
            background-color: #292e33;
            color: #fff;
          }
        }
      }
    }
  }

  &__nav {
    display: flex;
    width: 100%;
    gap: 8px;

    &--ques {
      display: block;
      width: 24px;
      height: 24px;
      background-image: url(./../media/images/icons/ques.png);
      background-position: center;
      background-size: cover;
    }
    &--social {
      display: block;
      width: 24px;
      height: 24px;
      background-image: url(./../media/images/icons/social.png);
      background-position: center;
      background-size: cover;
      position: relative;
      z-index: 1;
    }
  }
}

.icons-inner {
  display: flex;
  gap: 15px;
  margin-top: 29px;
  user-select: none;
  height: 22px;
}

.main__inner.main ~ .breadcrump-inner {
  border-top: 1px solid #fff;
  padding: 13px 0;
}

.main__inner.product ~ .breadcrump-inner {
  display: none;
}

.main__inner.page {
  min-height: 600px;

  h4 {
    flex-wrap: wrap;
  }
  
  img {
    height: 735px;
  }

  .main__inner-content {
    padding-top: 250px;
  }
}

.main__inner.main {
  h1 {
    cursor: pointer;
  }
}

.main__inner {
  // padding-bottom: 170px;
  min-height: 560px;
  max-height: 675px;

  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
    // flex-wrap: wrap;
    // padding-bottom: 170px;
  }

  h1 {
    max-width: 630px;
    font-family: "RubikOne";
    text-transform: uppercase;
    font-size: 72px;
    font-weight: 400;
    line-height: 78px;
    letter-spacing: 0em;
    text-align: left;
    color: rgb(255, 255, 255);
    // color: rgb(198, 33, 33);
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1360px) {
      font-size: 72px;
    }

    @media (max-width: 768px) {
      font-size: 51px;
    }
  }

  img,
  video {
    object-fit: cover;
    object-position: center;
    position: absolute;
    // min-height: 550px;
    // max-height: 600px;
    height: 800px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -2;
  }

  &-pad {
    position: absolute;
    height: 735px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #005baa;
    opacity: 0.1;
    z-index: -1;
  }

  &-content {
    width: 100%;
    padding-top: 100px;
    color: #ffff;
  }

  video {
    max-width: 550px;
    min-width: 450px;
    max-height: 500px;
    backdrop-filter: blur(100px);
    padding: 5px;
    border-radius: 20px;
    position: relative;
    object-position: center 50px;
  }

  h4 {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    max-width: 570px;
    margin-bottom: 25px;
  }

  .outer-span {
    position: relative;
    background-image: url("../media/images/headspan.svg");
    display: inline-block;
    padding: 15px;
    font-size: 19px;
    line-height: 21px;
    width: 360px;
    // max-width: 150px;
    overflow: hidden;
  }

  .inner-span {
    position: relative;
    white-space: nowrap;
    display: flex;
    gap: 155px;
    left: -800px;
    animation-duration: 16s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: slideLeft;
  }

  @keyframes slideLeft {
    from {
      left: -800px;
    }
    to {
      left: 0px;
    }
  }
}

h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  max-width: 456px;
}

.logo {
  &__img {
    text-decoration: none;
    width: 28px;
    height: 36px;
    margin-bottom: 0px;
  }

  &__text {
    font-size: 14px;
    letter-spacing: 2px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
  }
}

.menu {
  //перший рівень меню
  &__list {
    list-style: none;
    display: flex;
    margin-top: 27px;
    gap: 10px;
  }

  &__item {
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 2px 5px;
    font-size: 16px;
    line-height: 24px;
    transition: background-color 0.33s ease;
    user-select: none;
  }

  &__item.active a {
    color: #000;
  }

  &__item:hover a {
    color: #000;
  }

  &__item:hover svg path {
    transition: fill 0.3s ease;
    fill: #292e33;
  }

  &__item.active svg path {
    transition: fill 0.3s ease;
    fill: #292e33;
  }

  &__item:hover {
    background-color: #fff;
  }

  &__item.active {
    background-color: #fff;
  }

  &__item:hover .menu__list-second-inner {
    display: block;
  }

  &__link-wrapper {
    display: flex;
    z-index: 2;
  }

  &__link {
    transition: color 0.33s ease;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #fff;
    letter-spacing: 2px;
    margin-right: 5px;

    &--text {
      width: 100%;
    }

    &--count {
      margin-left: 10px;
      color: #676d73;
      font-weight: 400;
    }
  }

  &__link.active {
    background-color: #fff;
    padding: 0 5px;
    color: #000;
  }

  //другий рівень менюшок
  &__list-second-inner {
    display: none;
    position: absolute;
    top: 0px;
    left: 0;
    background-color: transparent;

    .room {
      margin-top: 32px;
      display: block;
      position: relative;
    }
  }
  &__list-second-inner.active {
    display: block;
  }

  &__list-second {
    // width: 125%;
    display: block;
    position: relative;
    top: 40px;
    left: 0;
    border-radius: 2px;
    font-weight: 400;
    background-color: #fff;
    // padding: 24px;
    text-align: left;
    transition: display 0.33s ease;
  }

  &__item-second {
    margin-bottom: 8px;
    padding: 0 8px;
    transition: background-color 0.3s ease;
    &:first-child {
      padding-top: 16px;
    }
    &:last-child {
      padding-bottom: 16px;
    }
  }

  &__item-second:hover {
    border-radius: 2px;
    // background-color: #292e33;
  }

  &__item-second:hover .menu__link-second {
    color: #fff;
  }

  &__item-second:hover .menu__link-second .menu__link-second--arr svg path {
    fill: #fff;
  }

  &__item-second.active {
    padding-right: 0;
  }

  &__item-second.active .menu__link-second {
    background-color: #292e33;
    border-radius: 2px 0px 0px 2px;
    // padding: 8px 16px;
    left: -8px;
    width: calc(100% + 8px);
    color: #fff;
    padding-right: 100px;
  }

  &__item-second.active .menu__link-second .menu__link-second--arr svg path {
    fill: #fff;
  }

  &__link-second {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    color: #292e33;
    font-size: 16px;
    padding: 8px 16px;
    transition: color 0.3s ease;

    &:hover {
      background-color: #292e33;
      border-radius: 2px;
      color: #fff;
    }

    &--count {
      margin-left: 5px;
      color: #676d73;
      font-weight: 400;
    }
  }

  &__item-second.active .arrow {
    display: none;
  }
  &__item-second.active .arrow-active {
    display: flex;
  }

  //третій рівень менюшок

  &__list-three {
    display: none;
    position: absolute;
    width: 115%;
    top: 0;
    left: 102%;
    border-radius: 2px;
    font-weight: 400;
    background-color: #fff;
    padding: 24px 0 24px 24px;
    text-align: left;
    transition: display 0.33s ease;
  }

  &__list-three.active {
    display: block;
  }

  &__item-three {
    padding: 8px 15px 8px 8px;
    margin-bottom: 8px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #292e33;
    }
  }

  &__item-three:hover a {
    color: #fff;
  }

  &__link-three {
    display: flex;
    position: relative;
    align-items: center;
    color: #292e33;
    font-size: 16px;
    transition: color 0.33s ease;

    &--text {
    }

    &--count {
      margin-left: 10px;
      color: #676d73;
      font-weight: 400;
    }

    &--img {
      margin-right: 10px;
    }
  }

  &__item-three.active .menu__link-three .menu__link-three--arr svg path {
    fill: #fff;
  }

  &__item-three .arrow {
    display: block;
  }
  &__item-three .arrow-active {
    display: none;
  }

  &__item-three.active .arrow {
    display: none;
  }

  &__item-three.active .arrow-active {
    display: flex;
  }
}
/// Стилізація другого рівня менюшок і третього
#submenu_0 {
  width: 160px;
}
#submenu_1 {
  width: 216px;
}
#submenu_2 {
  width: 288px;
}
#submenu_3 {
  width: 151px;
}

#menu__list-three_0_0 {
  width: 320px;
  padding: 15px 0 15px 15px;
}

#menu__list-three_2_1 {
  width: 245px;
}

.arrow {
  display: flex;
  align-items: center;
  margin-left: 7px;
}

.arrow-active {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  display: none;
  align-items: center;
}

.sugar {
  height: min-content;
  width: 125% !important;
  top: 0;
  left: 110%;
  position: absolute;
  padding: 15px;
  background-color: #292e33;
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__item {
    padding: 0 15px;
    height: 80px;
    border: 1px solid #45494d;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__img {
    height: 70px;
    width: 50px;
    object-fit: contain;
  }

  &__name {
    font-size: 16px;
    color: #fff;
    width: 150px;
    text-align: center;
    border-right: 1px solid #45494d;
  }

  &__weight {
    font-size: 16px;
    text-align: center;
    width: 90px;
    color: #fff;

    &--one {
      font-size: 16px;
      text-align: center;
      width: 90px;
      color: #fff;
      border-right: 1px solid #45494d;
    }
  }
}

.pop-inner {
  top: 20%;
  position: absolute;
}

.pop-inner.\31 {
  top: -15%;
  left: -10%;
}

.pop-inner.\32 {
  left: -20%;
}

.pop-inner.\33 {
  left: -60%;
}

.pop-inner.\34 { 
  left: -100%;
}

.pop-inner.\35 {
  left: -150%;
}

.room {
  width: 272px;
  height: fit-content;
  padding: 8px;
  background-color: #292e33;
  border-radius: 2px;
  cursor: pointer;

  &__title {
    display: flex;
    align-items: center;
    border-radius: 2px;
    border: 1px solid var(--7, #676d73);
    padding: 9px;

    &--img {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background-image: url(./../media/images/icons/room.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &--text {
      text-transform: uppercase;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }

    &--links {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    span {
      font-weight: 700;
    }
  }

  &__enter {
    width: 136px;
    padding: 8px 12px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    border-radius: 2px;

    &:hover {
      background-color: #45494d;

      .room__enter--text {
        color: #fff !important;
      }
    }

    &--img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background-image: url(./../media/images/icons/enter.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &--text {
      color: #fff;
      font-weight: 400;
    }
  }

  &__reg {
    width: 136px;
    padding: 8px 12px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    border-radius: 2px;

    &:hover {
      background-color: #45494d;

      .room__reg--text {
        color: #fff !important;
      }
    }

    &--img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background-image: url(./../media/images/icons/registration.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &--text {
      font-size: 16px;
      color: #ffffff;
    }
  }

  &__top-entry {
    width: 136px;
    padding: 8px 12px;
    border: 1px solid #c2c7cc;
    display: flex;
    align-items: center;
    border-radius: 2px;
    margin-bottom: 10px;

    &--img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background-image: url(./../media/images/icons/enter.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &--text {
      color: #292e33;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__top-reg {
    width: 136px;
    padding: 8px 12px;
    border: 1px solid #c2c7cc;
    display: flex;
    align-items: center;
    border-radius: 2px;

    &--img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background-image: url(./../media/images/icons/registration.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &--text {
      font-size: 14px;
      color: #292e33;
      font-weight: 400;
    }
  }

  &__content {
    display: flex;
    gap: 32px;
  }

  &__content-card {
    width: 100%;
    height: 256px;
    border: 2px solid #c2c7cc;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &--number {
      font-family: "Rubik Mono One", sans-serif;
      font-size: 40px;
      color: #292e33;
      opacity: 50%;
      margin-bottom: 10px;
    }

    &--line {
      width: 20px;
      background-color: #292e33;
      height: 3px;
      border-radius: 3px;
      margin-bottom: 10px;
    }

    &--text {
      font-family: "Rubik Mono One", sans-serif;
      font-size: 40px;
      color: #292e33;
    }
  }
}

.room__title.pop {
  border: none;
}

.seed {
  padding: 0 0 112px 0;
  display: flex;
  gap: 32px;

  &__list {
    width: 100%;
  }

  &__item {
    position: relative;
    padding-left: 15px;
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #c2c7cc;

    &--number {
      font-size: 32px;
      color: #676d73;
      margin-right: 15px;
    }

    &--text {
      font-size: 16px;
      color: #292e33;
    }

    &--text.active {
      color: #fff;
      font-weight: 700;
    }

    &::after {
      content: "";
      position: absolute;
      width: 32px;
      height: 10px;
      right: 0;
      background-image: url("../media/images/icons/arr-long-left.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__item.active {
    background-color: #292e33;

    &::after {
      content: "";
      position: absolute;
      width: 64px;
      height: 13px;
      right: -20px;
      background-image: url(../media/images/icons/arr-long-right.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__content {
    width: 100%;
    border: 1px solid #c2c7cc;
    border-radius: 2px;
    padding: 22px 32px 56px;
    font-size: 16px;
    color: #292e33;
  }
}

/*================================================
  SliderHead styles 
=================================================*/

.swiper-wrapper {
  max-height: 600px;
}

.swiper-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 46px;
}

.swiper-pagination-bullet {
  background: url("..//media//images/indicator.svg");
  width: 10px;
  height: 10px;
}

.swiper-pagination-bullet-active {
  width: 21px;
  height: 21px;
  background: url("..//media//images/indicator-active.svg");
}

.swiper-vertical .swiper-button-next,
.swiper-vertical .swiper-button-prev {
  transform: rotate(90deg);
  left: calc(100% - 60px);
  width: 24px;
  height: 24px;
  transform-origin: left center;
  &::after {
    display: none;
  }
}
.swiper-vertical .swiper-button-prev {
  background-image: url("../media/images/arrow-circle-next.svg");
  top: calc(var(--swiper-navigation-size) / 2);
}
.swiper-vertical .swiper-button-next {
  background-image: url("../media/images/arrow-circle-prev.svg");
  top: auto;
  bottom: calc(var(--swiper-navigation-size) / 2);
}

/*================================================
  Menu mobile 
=================================================*/

.menu-mobile {
  width: 55px;
  height: 45px;
  margin: 4px 0;
  background-color: rgb(255 255 255 / 0.3);
  backdrop-filter: blur(10px);
  border: none;
  border-radius: 4px;
  display: none;

  span {
    background-color: #fff;
    display: inline-block;
    height: 4px;
    position: relative;
    width: 45px;
    border-radius: 2px;
    top: -3px;

    &::before,
    &::after {
      background-color: #fff;
      content: "";
      height: 4px;
      left: 0;
      position: absolute;
      transform: rotate(0);
      transition: transform 0.5s;
      width: 40px;
      border-radius: 2px;
    }
    &::before {
      top: 10px;
    }
    &::after {
      top: -10px;
    }
  }
}
.menu-mobile.active span {
  background-color: transparent;

  &::before {
    top: 0;
    transform: rotate(-45deg);
  }
  &::after {
    top: 0;
    transform: rotate(45deg);
  }
}

.close-mobile {
  position: absolute;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 20px);
  right: -30px;
  top: 10px;

  svg {
    opacity: 0.5;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

@import url(../../src/views/vacancies/Vacancies.scss);

/*================================================
  Breadcrumps styles 
=================================================*/

.breadcrump-inner {
  background-image: url("./../media/images/bread-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0;
  color: #fff;
  text-transform: lowercase;
  height: 80px;

  &__logo {
    display: flex;
    align-items: center;
    gap: 45px;
  }

  // margin-bottom: 20px;

  .container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  a {
    line-height: 0;
    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  @media (max-width: 1051px) {
    .container {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

/*================================================
  Header media
=================================================*/

@media (max-width: 1360px) {
  .container {
    padding: 0 15px;
  }
}

@media (max-width: 1050px) {
  .menu-mobile {
    display: block;
  }

  .close-mobile {
    display: flex;
  }

  .main .room {
    display: none;
  }

  .menu__link-wrapper {
    z-index: 0;
  }

  .main__wrapper {
    flex-direction: column;
    background-color: #292e33;
    color: #fff;
    position: absolute;
    width: 90%;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 8px;
    left: -100%;
    top: 0;
    transition: all 0.3s;

    .room {
      display: block;
      width: auto;
      padding: 0;
    }
  }

  .main__wrapper.active {
    left: 0;
  }

  .menu__list {
    flex-direction: column;
    align-items: baseline;
  }

  .main__contact--company {
    &:hover .main__contact-drop {
      left: 130px;
      top: -30px;
    }
  }

  .main__contact-drop-inner {
    padding: 16px 24px 16px 24px;
  }

  .main__contact {
    flex-direction: column;
    margin-top: 0;
  }

  .menu__item {
    flex-direction: column;
  }

  .menu__list-second-inner {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 360px;
    .room {
      // display: none;
      margin-top: 0;
    }
  }

  .menu__item-second:last-child {
    padding-bottom: 0;
  }

  .menu__list-second,
  .menu__list-three {
    position: relative;
    padding: 0;
    top: 0px;
    left: 0;
    margin-top: 0;
    width: 100%;
  }
  .menu__link-three {
    flex-wrap: wrap;
    max-width: 360px;
  }
  .sugar {
    position: relative;
    left: 0;
    width: 100% !important;
    max-width: 260px;
    padding: 0;
    margin-top: 10px;
  }

  .main__inner.product {
    min-height: 100vh;
    max-height: initial;
    padding-bottom: 20px;

    .main__inner-pad,
    img {
      height: 200%;
    }
  }

  .main__inner.page {
    // max-height: 568px;
    // min-height: 425px;
    padding-bottom: 0;
    display: flex;
    align-items: flex-end;

    .main__inner-content {
      padding-top: 0;
    }

    .main__inner-pad,
    img {
      height: 725px;
    }

    .container {
      // padding-bottom: 10px;
      margin-left: 0;
    }
  }

  .main__inner.main {
    padding-bottom: 0;
    min-height: 850px;

    .container {
      flex-direction: column-reverse;
      flex-wrap: wrap;
      gap: 50px;
    }
    video {
      max-width: 100%;
      max-height: 400px;
      margin-bottom: -150px;
      border-radius: 0;
    }
    .video-bg {
      height: 110%;
    }
    h1,
    h4 {
      max-width: 100%;
      text-align: center;
    }
    h3 {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .swiper-wrapper {
    max-height: 850px;
  }

  .breadcrump-inner {
    // margin-top: 35px;
  }
}

@media (max-width: 730px) {
  .main__inner-content {
    h1 {
      font-size: 62px;
      line-height: 70px;
    }
  }

  .main__inner.contacts {
    .main__inner-content {
      padding-top: 15px;

      li {
        max-width: 300px;
        margin-bottom: 5px;
      }
    }
  }
}

@media (max-width: 660px) {
  .main__inner.question {
    max-height: 860px;
    .main__inner-pad,
    img {
      height: 920px;
    }
    .main__inner-content {
      padding-top: 50px;
    }
  }
}

@media (max-width: 560px) {
  .main__inner.main {
    position: relative;
    top: -50px;
    min-height: 570px;

    h1 {
      margin-bottom: 20px;
    }

    h4 {
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
    video {
      max-height: 240px;
      padding: 0;
      min-width: 100%;
      object-position: center 10px;
    }
    .video-bg {
      object-position: 100%;
    }
    .outer-span {
      margin: 0 auto;
    }
    .swiper-wrapper {
      max-height: 570px;
    }
  }

  .main__inner .outer-span {
    padding: 10px;
  }

  header {
    position: relative;
    margin-top: -3px;
    z-index: 2;
  }

  .main__inner.page img {
    object-position: 84% -60px;
  }

  .main__inner.page {
    min-height: 545px;
    .main__inner-pad,
    img {
      height: 635px;
    }
  }

  .main__inner-content {
    h1 {
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 40px;
    }
    h4 {
      margin-bottom: 40px;
    }
  }

  .swiper-vertical .swiper-button-next,
  .swiper-vertical .swiper-button-prev {
    left: calc(100% - 14px);
  }

  .swiper-pagination {
    margin-right: -4px;
  }

  .breadcrump-inner {
    padding: 25px 0;
    margin-top: -30px;
  }

  .icons-inner {
    margin-top: 5px;
  }

  .main__contact-drop-inner {
    padding: 5px;
    max-width: 100%;
  }

  .main__contact--company:hover .main__contact-drop.cabinets {
    top: -260px;
  }

  // video mobile background

  .video-bg {
    display: none;
  }
  .mobile-bg {
    background-color: rgba(78 82 101);
    object-fit: cover;
    object-position: center;
    position: absolute;
    height: 800px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -2;
  }

  .main__nav--social {
    display: none;
  }
}
