.contactsWrapper {
  color: rgba(232, 216, 216, 0.5);
// display: flex;
  border-bottom: 1px solid  #292E33; 
  padding-top: 54px;
  padding-bottom: 104px;
  

  @media (max-width: 1360px) {
 
  }
  
  @media (max-width: 768px) {
  display: fl;
  }
}

.contactsWrapper_title {
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
  margin-bottom: 16px;
  width: 25%;

  @media (max-width: 1360px) {
 
  }
  
  @media (max-width: 768px) {
  // flex-direction: column;
  }
  
}

// .contactsWrapperContent {
//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;

//   @media (max-width: 1360px) {
 
//   }
  
//   @media (max-width: 768px) {
//   flex-direction: column;
//   }
// }

.contacts_title {
  display: block;
  color: var(--5, #C2C7CC);
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
}

.contacts_table {
  width: 75%;
}

.contacts_table__icon {
  

  @media (max-width: 1360px) {
 
  }
  
  @media (max-width: 768px) {
  display: none;
  }
}

.contacts_table__body {
  // display: flex;
  // flex-wrap: wrap;
}

.contacts_icon__item {
  display: flex;
  
  margin-bottom: 8px;
  
}

.contacts_icon {
  // margin-left: auto;
  // display: block;
  margin-left: 10px;


}

.contacts_text__decorLocation {
  color: var(--5, #C2C7CC);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.contacts_text__decorWord {
  color: var(--6, #45494D);
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
}

.contacts_email__decor {
  color: var(--5, #C2C7CC);
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; 
}

.contacts_email__decorAdres {
  color: var(--7, #676D73);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contacts_table__bodyItem {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 25%;
  // margin-bottom: 16px;

}

.contacts_table__bodyLink {
  // width: 100%S;
  // display: flex;
  // margin-right: auto;
}

.contacts_phone__decor0 {
  color: var(--7, #676D73);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contacts_phone__decor1 {
  color: var(--5, #C2C7CC);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contacts_phone__decor2 {
  color: var(--5, #C2C7CC);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

// =================

.social_list {
  display: flex;
  
  img {
    margin-right: 28px;
  }
}

.contacts_text {
  color: var(--5, #C2C7CC);
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 14px;
margin-bottom: 18px;
}