table {
    margin-bottom: 60px;
    border-collapse: separate;
    border-spacing: 10px;

    th {
        padding: 20px 0;
    }

    td {
        span {
            color: #292E33;
            border-bottom: 1px solid #959EA6;
            padding: 20px 0;
            text-align: left;
        }
    }

    td.light,
    td.middle,
    td.hard {
        border-top: 1px dashed;
        text-align: right;
        font-weight: 700;
        padding: 15px;
    }

    tr {
        
        &:last-child {
            td.light,
            td.middle,
            td.hard {
                border-bottom: 1px dashed;
            }
        }
    }

    span {
        font-size: 16px;
        font-weight: 400;
        color: #676D73;
    }
}

.light {
    color: #C19C65;
    font-weight: 700;
}
.middle {
    color: #845713;
    font-weight: 700;
}
.hard {
    color: #292E33;
    font-weight: 700;
}