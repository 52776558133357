.logoInfoWrapper {
  color: rgba(232, 216, 216, 0.5);

  border-bottom: 1px solid #292e33;

  padding-bottom: 56px;

  height: 72px;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}

.logo_content {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;

  .logo {
    margin-right: 32px;
  }

  p {
    color: rgba(103, 109, 115, 1);
  }
}
