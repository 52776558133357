.inputWrapper {
  position: relative;
  width: 192px;

  // user-select: none;
  margin-bottom: 32px;

  // border: 1px solid tomato;

  input {
    width: 100%;
    height: 40px;
    background-color: rgba(194, 199, 204, 1);
    border-radius: 2px;
    border: none;
    padding: 8px 37px 8px 16px;
    &::placeholder {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.1em;
      text-align: center;
    }
  }

  .iconButton {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  // button {
  //   position: absolute;
  //   top: 0px;
  //   right: -105px;
  //   border: none;
  //   background-color: transparent;;
  // }
}

// ==========================================

.filterWrapper {
  ul {
    display: flex;
    gap: 16px;
    margin-bottom: 64px;
    flex-wrap: wrap;

    li {
      button {
        width: 120px;
        min-height: 40px;
        background-color: transparent;
        border: 1px solid rgba(194, 199, 204, 1);
        border-radius: 2px;
        color: #45494d;

        font-family: Open Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }

      .active__button {
        background-color: rgba(194, 199, 204, 1);
        color: #fff;
      }
    }
  }
}

// ====================================================
// .card {
//   align-items: flex-start;
// }

.cardContainer {
  // background-color: tomato;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px 88px -16px;

  li {
    // border: 1px solid tomato;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    text-align: center;

    width: calc(100% / 3 - 32px);

    margin: 16px 16px 40px 16px;

    img {
      // max-width: 376px;
      // max-height: 304px;
      // width: 100%;
      // height: 100%;
      // object-fit: cover;
      // object-position: center;
      margin-bottom: 7px;
      max-height: 300px;
      height: 100%;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
    }

    h3 {
      position: relative;
      font-family: Open Sans;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 15px;
      color: black;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: -10px;
        left: 0;
        width: 10%;
        border-top: 2px solid black;
      }
    }

    .date {
      margin-bottom: 21px;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(103, 109, 115, 1);
    }

    .description {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: auto;
    }

    .buttonCard {
      margin-top: 18px;
      width: 120px;
      min-height: 40px;
      background-color: rgba(194, 199, 204, 1);
      border: none;
      border-radius: 2px;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      transition: all 0.3s;

      &:hover {
        background-color: rgb(161, 165, 168);
      }
    }

    @media (max-width: 768px) {
      width: calc(100% - 32px);
    }
  }
}

// =====================================

.containerPagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 300px;
  // border: 1px solid green;

  .overflowWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 400px;
    // border: 1px solid rgb(78, 42, 184);
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(242, 242, 242, 1) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(242, 242, 242, 1) 100%
      );
      z-index: 1;
    }

    @media (max-width: 768px) {
      width: 200px;
      // background: green;
    }
  }

  .pagination_dots {
    position: absolute;
    display: flex;
    cursor: pointer;
    // border: 1px solid rgb(226, 52, 147);
    left: 35%;
    // left: 30px;

    transition: transform 1.9s ease;

    @media (max-width: 768px) {
      left: 34%;
    }
  }

  .decorBorder {
    border: 1px solid rgba(194, 199, 204, 1);
    border-radius: 2px;
    width: 64px;
    height: 48px;

    @media (max-width: 768px) {
      width: 48px;
      height: 32px;
    }
  }

  .prevArrow,
  .nextArrow {
    fill: rgba(69, 73, 77, 1);
    font-size: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: fill 0.3s ease, color 0.3s ease;
    width: 24px;
    height: 24px;
    // background-color: tomato;

    &:hover {
      fill: rgba(194, 199, 204, 1);
    }
  }

  .prevArrow {
    margin-left: auto;
    // margin-right: 16px;
  }

  .nextArrow {
    margin-right: auto;
    // margin-left: 16px;
  }

  .pagination_dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    // align-items: initial;
    height: -webkit-fill-available;

    .dot {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(194, 199, 204, 1);
      border-radius: 2px;
      width: 40px;
      height: 32px;

      @media (max-width: 768px) {
        width: 32px;
        height: 22px;
      }
    }

    .activeDot {
      border: none;
      width: 64px;
      height: 48px;
      margin-left: 28px;
      margin-right: 28px;

      @media (max-width: 768px) {
        width: 32px;
        height: 22px;
        margin-left: 16px;
        margin-right: 16px;
        // align-items: initial;
      }
    }
  }

  .firstPageButton,
  .lastPageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(194, 199, 204, 1);
    border-radius: 2px;
    width: 40px;
    height: 32px;
    margin-left: 16px;
    margin-right: 16px;
    background: transparent;
    color: rgba(103, 109, 115, 1);

    @media (max-width: 768px) {
      width: 32px;
      height: 22px;
    }
  }
}

.wrapLostBtn {
  width: 100px;
}
