.popup {
    position: absolute;
    min-width: 920px;
    width: 100%;
    top: 20%;
    left: -150%;
    border-radius: 2px;
    // background: #5d6166;
    background: #C2C7CC;
    margin-top: 70px;

    p {
        margin-bottom: 20px;
        color: black;
        span {
            font-weight: 700;
        }
    }
    li span {
        font-weight: 700;
        color: black;
    }
    li, div{
        color: black;
    }

    a:hover{
        color: #fff;
    }

    &__inner {
        padding: 32px 64px;
    }
    &__head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px;
        border-bottom: 1px solid #FFFFFF;
        margin-bottom: 8px;
    }

    .room__title {
        border: none !important;
    }
}

.popup-main__item {
    padding-bottom: 24px;
    border-bottom: 1px solid #FFFFFF;
    margin-bottom: 8px;
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
}

@media (max-width: 560px) { 
    .popup {
        min-width: 100vw;
        width: 100%;
        top: 65%;
        left: -20px;
        margin-top: 50px;

        &__inner {
            padding: 15px 10px;
        }

        &__head {
            flex-wrap: wrap;
        }
    }
}