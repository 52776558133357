@import "mixins.scss";
@import "variables.scss";

.acord {
  display: flex;
  gap: 32px;
  width: 100%;

  @include onTab {
    flex-direction: column;
  }

  &__list {
    display: flex;
    width: 100%;
    gap: 32px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__item {
    position: relative;
    padding-left: 15px;
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid $acordBorderColor;
    font-size: 24px;
    width: 100%;
    cursor: pointer;

    @include onTab {
      width: 272px;
      height: 56px;
      font-size: 16px;
    }

    &--number {
      color: $acordNumberColor;
      margin-right: 15px;
      font-weight: 300;
      font-size: 32px;

      @include onTab {
        font-size: 16px;
      }
    }

    &--text {
      color: $acordTextColor;
    }

    &--text.active {
      color: $acordTextActiveColor;
      font-weight: 700;
      padding-right: 40px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 32px;
      height: 10px;
      right: 0;
      background-image: url('../media/images/icons/arr-long-left.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      @include onTab {
        transform: rotate(180deg);
        width: 24px;
      }
    }
  }

  &__item.active {
    background-color: $acordBgActive;

    &::after {
      content: "";
      position: absolute;
      width: 64px;
      height: 13px;
      right: -20px;
      background-image: url('../media/images/icons/arr-long-right.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      @include onTab {
        content: "";
        position: absolute;
        width: 24px;
        height: 10px;
        right: 16px;
        background-image: url('../media/images/icons/arr-long-left.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(360deg);
      }
    }
  }

  &__content.active {
    display: block;
  }

  &__content {
    display: none;
    position: relative;
    width: 100%;
    border: 1px solid $acordBorderColor;
    border-radius: 2px;
    padding: 22px 32px 56px 56px;
    font-size: 16px;
    color: $acordTextColor;

    .acord-details {
      // position: relative;
      // right: 20px;
      // bottom: 20px;
      position:initial;
      padding: 7px 10px;
      border-radius: 2px;
      border: 1px solid $acordBorderColor;
      transition: all 0.3s;
      
      &:hover {
        background-color: #292e33;
        color: #fff;
      }
    }

    @include onTab {
      padding: 22px 16px 56px;
      width: 272px;
    }
  }

  &__content.tech {
    padding: 32px;
    transition: all 0.3s;
    
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $acordBorderColor;
      border-radius: 2px;
      background-color: transparent;
      color: $acordTextColor;
      gap: 10px;
      max-width: 300px;
      width: 100%;
      margin-top: 28px;
      transition: all 0.3s;
      cursor: pointer;
      padding: 10px;

      &:hover {
        background-color: #292e33;
        color: #fff;

        svg path{
          fill: #fff;
        }
      }
    }
  }
  
  &__content--phone.active {
    display: none;

    @include onTab {
      display: block;
    }
  }

  &__content--desktop.active {
    display: block;

    @include onTab {
      display: none;
    }
  }
}

.accord-docs {
  padding-top: 24px;
  clip: rect(0,0,0,0);
  position: absolute;
  opacity: 0;
  transition: all 0.3s;
}

.accord-docs.active {
  clip: rect(1,1,1,1);
  position: relative;
  opacity: 1;
}
