
.NewsWrapper {
    padding-top: 30px;

@media (max-width: 1360px) {
 
}

@media (max-width: 768px) {
  
}
}

.news-page {
    display: flex;
    gap: 20px;
    margin-bottom: 60px;

    &__title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        gap: 10px;
    }
    img {
        height: 100%;
        width: auto;
        max-width: 550px;
        max-height: 550px;
        object-fit: cover;
        cursor: pointer;
    }
}

@media (max-width: 1051px) {
    .news-page {
        flex-wrap: wrap;
    }
}


