.cardsWrapper {
  padding-top: 200px;
  padding-bottom: 240px;
  background:  #222426;


  ul {
    display: flex;
    justify-content: space-around;


    li:not(:last-child) {
      @media (max-width: 768px) {
        margin-bottom: 152px;
      }
    }
    


    @media (max-width: 768px) {
flex-wrap: wrap;
    }
  }



@media (max-width: 1360px) {
 
}

@media (max-width: 768px) {

  padding-top: 56px;
  padding-bottom: 152px;
  
}
}


.cardWrapper2 {
  width: 370px;
 
   
   .decorV {
     display: block;
     margin-left: auto;
     margin-right: auto;
     margin-bottom: 30px;
   }
   
   
   .photoWrapper {
     position: relative;
     display: flex;
     justify-content: center;
     margin-bottom: 50px;
     
     .photo {
       position: absolute;
       top: 50%; 
       left: 50%; 
       transform: translate(-50%, -50%);
     }
 
 
     
   }
 
   
 h3 {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 30px;
 
   font-family: Open Sans;
 font-size: 24px;
 font-weight: 800;
 line-height: 32px;
 letter-spacing: 0em;
 text-align: center;
 color: rgba(242, 242, 243, 1);
 
 span {
   font-weight: 400;
 }
 
 }
 
 p {
 text-align: center;
 font-family: Open Sans;
 font-size: 16px;
 font-weight: 400;
 line-height: 24px;
 letter-spacing: 0em;
 text-align: center;
 color: rgba(242, 242, 243, 1);
 
 }
   
   
   
   @media (max-width: 1360px) {
     
 }
 
 @media (max-width: 768px) {
   
 }
 }
 
 
 
