@import "./../../styles/mixins.scss";
@import "./../../styles/variables.scss";

$primary: #292e33;
$secondary: #c2c7cc;
$gray: hsl(0, 0%, 61%);

// popup register styles
.nc-Popupify_popup>div {
  &::-webkit-scrollbar {
    width: 6px;
  }
    
  &::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 12px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: $secondary;
  }
}

.nc-Popupify_popup-container {
  background-color: #fff;
  padding: 30px;
  width: 475px;
  overflow-y: auto;
  max-height: 97vh;

  h4 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
    user-select: none;
  }

  @media (max-width: 500px) { 
    padding: 20px;
    width: 100vw;
  }
}

.entrance__btn.form {
  margin: 50px auto 0 auto;
  background-color: transparent;
  user-select: none;
  cursor: pointer;
  width: 230px;
  padding: 12px;
  transition: all 0.3s;

  .entrance__text {
    font-size: 16px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &:hover {
    background-color: #ececec
  }
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%;
  user-select: none;

  .form-control {
    margin-top: 10px;
    font-family: system-ui, sans-serif;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: 0.5em;
  }

  .user-exist {
    margin-top: 10px;
    font-size: 14px;
    font-weight:600; 
    color: $primary;

    span {
      color: #007bff;
      cursor: pointer;
      font-weight:500; 
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  .form-control + .form-control {
    margin-top: 1em;
  }
  
  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;
  
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.1em solid $gray;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
  
    display: grid;
    place-content: center;
  }
  
  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $primary;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

}

.form-alert {
  color: #f44336;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 16px;
  color: rgb(0,0,0);
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 16px;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 14px;
  color: $gray;
}

.label-pass {
  color: $primary;
  span {
    display: block;
  }
}

.form__field:focus {
  color: $primary !important;
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: $primary;
    font-weight:700;    
  }
  padding-bottom: 6px;  
  // font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary,$secondary);
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}

// select styles
.form__group.select {
  margin-top: 0px;
  padding-top: 10px;

  label {
    color: $primary;
    display: inline-block;
    margin-bottom: 10px;
  }
}

.form__group.alert { 
  input,
  label {
    color: #f44336;
  }
}

.css-b62m3t-container {
  position: relative;
}

.css-t3ipsp-control {
  border-color: #292e33 !important;
  box-shadow: none !important;
}
//------------------------------------

.loader-study {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 100px 0 50px 0;

  .loader_loader__4IIa4 .loader_tile__3qgkG {
    color: #292e33;
  }
}

.study {
  padding: 112px 0 297px;

  // @include onTV {
  //   padding: 191px 0 297px;
  // }

  @include onPhone {
    padding: 32px 0 96px;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 36px;

    @include onPhone {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__top-title {
    color: $studyTitleColor;

    &--year {
      font-size: 32px;
      font-weight: 400;
      opacity: 50%;
      margin-bottom: 12px;
    }

    &--text {
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      padding-right: 10px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }

  &__content-card {
    width: 48%;
    height: 100%;
    min-height: 256px;
    font-family: "Rubik Mono One", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid $studyCardBorderColor;
    border-radius: 2px;
    padding: 10px;
    color: $studyCardColor;

    &--number {
      font-size: 40px;
      opacity: 50%;
      margin-bottom: 10px;

      @include onPhone {
        font-size: 30px;
      }
    }

    &--line {
      display: block;
      width: 20px;
      height: 3px;
      border-radius: 3px;
      margin-bottom: 10px;
      background-color: $studyCardLineColor;
    }

    &--text {
      font-size: 40px;
      color: $studyCardTextColor;

      @include onPhone {
        font-size: 25px;
      }
    }
  }
}



.entrance {

  @include onPhone {
    margin-bottom: 56px;
  }

  &__btn {
    width: 136px;
    padding: 8px 12px;
    border: 1px solid $studyBtnBorderColor;
    display: flex;
    align-items: center;
    border-radius: 2px;
    margin-bottom: 10px;

    @include onPhone {
      width: 128px;
    }
  }

  &__img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &--entry {
      background-image: url(./../../media/images/icons/enter.png);
    }

    &--reg {
      background-image: url(./../../media/images/icons/registration.png);
    }
  }

  &__text {
    color: $studyBtnTextColor;
    font-weight: 400;
    font-size: 14px;
  }
}

// event styles 

.study__content-event {
  display: flex;
  flex-direction: column;
  gap: 30px;

  table {
    border-collapse: collapse;

    td {
      padding: 5px;

      span {
        color: initial;
        border-bottom: initial;
        padding: initial;
        text-align: initial;
      }
    }
  }

  h3 {
    margin-bottom: 10px;
  }
}

.event-location {
  ul {
    list-style-type: disc;
    margin-left: 20px;

    .location {
      margin-bottom: 5px;
    }

    .location.active {
      font-weight: 700;
    }
  }
}

.event-materials {

  h4 {
    margin-bottom: 5px;
  }

  a {
    color:#007bff;

    &:hover {
      text-decoration: underline;
    }
  }
}

.event-grafics {

  &__tab * {
    background-color: transparent !important;
  }

  h3 {
    margin-bottom: 15px;
  }
}

.react-tabs__tab:focus:after {
  background: transparent !important;
}

@media (max-width: 868px) {
  .study__content-card {
    width: 100%;
  }
}
