@mixin onTab {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin onPhone {
  @media (max-width: 1360px) {
    @content;
  }
}

@mixin onTV {
  @media (min-width: 1920px) {
    @content;
  }
}
