

.NewsWrapper {
  // position: relative;
  // background-color: red;
  padding-top: 30px;


@media (max-width: 1360px) {
 
}

@media (max-width: 768px) {
  
}
}


