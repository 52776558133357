.ShipmentWrapper {
  padding-top: 106px;
  padding-bottom: 64px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c2c7cc;
    border-radius: 2px;
    background-color: transparent;
    color: #292e33;
    gap: 10px;
    max-width: 300px;
    width: 100%;
    margin-top: 28px;
    transition: all 0.3s;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: #292e33;
      color: #fff;

      svg path{
        fill: #fff;
      }
    }
  }

  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 90px;
      }

      .descriptionWrapper {
        max-width: 570px;
        border: 1px solid rgba(255, 255, 255, 1);

        &:not(:last-child) {
          margin-bottom: 90px;
        }

        .description {
          position: relative;
          margin-bottom: 10px;

          font-family: Open Sans;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;

          span {
            position: absolute;
            left: -30px;
            color: rgba(194, 199, 204, 1);


            @media (max-width: 768px) {
              left: -22px;
            }

          }
        }


      }
    }
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}
