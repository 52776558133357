.wrapperContent {
  .location__list {
    display: flex;
    margin-bottom: 63px;
    margin-right: 50px;

    .active::after {
      transform: scale(1, 1);
      opacity: 1;
      background-color: rgba(255, 255, 255, 1);
    }

    li {
      position: relative;
      display: flex;
      flex-direction: column;

      cursor: pointer;
      padding-bottom: 10px;

      font-family: Open Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(255, 255, 255, 1);

      &:not(:last-child) {
        margin-right: 32px;

        @media (max-width: 768px) {
          // font-size: 10px;
          
          margin-right: 0;
         }
      }

      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        background-color: transparent; /* За замовчуванням, не підсвічено */
        border-radius: 2px;
        opacity: 0;
        transform: scale(0, 0);
        transform-origin: bottom left;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:hover::after,
      &:focus::after {
        background-color: rgba(
          255,
          255,
          255,
          1
        ); /* Змініть кольори на ваш смак */
        opacity: 1;
        transform: scale(1, 1);
      }

      span {
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }

      
    }

    @media (max-width: 768px) {
      // background-color: tomato;
      display: flex;
      // flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }

  .contacts__List {
    // width: 400px;
    max-width: 300px;

    li:not(:last-child) {
      margin-bottom: 30px;
    }

    .contacts__text {
      margin-left: 8px;
      margin-right: auto;
    }

    .contacts__item--location {
      display: flex;
      display: flex;
      justify-content: center;
      color: rgba(255, 255, 255, 1);

      font-family: Open Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      // max-width: 280px;
    }

    .contacts__item--email,
    .contacts__item--phone {
      display: flex;
      color: rgba(255, 255, 255, 1);

      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }

    .contacts__item--phone {
      ul {
        display: flex;
        flex-direction: column;
        width: 100%;

        li {
          display: flex;
          margin-bottom: 0px;

          .contacts__text {
            color: rgba(255, 255, 255, 1);
            margin-right: auto;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;

    margin-right: 0;
  }
}

.icon {
  fill: rgba(255, 255, 255, 1);
}
